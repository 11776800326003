import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '../Common/Pagination';
import moment from 'moment';
import * as XLSX from 'xlsx';
import Skeleton from '../Common/Skeleton';


const VerificationReport = () => {

    const [data, setData] = useState('');

    const [loader, setLoader] = useState(false);
    const [excelLoader, setExceLoader] = useState(false);

    const [searchMobile, setSearchMobile] = useState('');
    const [searchMobileErr, setSearchMobileErr] = useState(false);

    const [date, setDate] = useState('');
    const [dateErr, setDateErr] = useState(false); 

    const [download, setDownload] = useState(false);

    // States for Pagination ----------
      const [pageNo, setPageNo] = useState(1);
      const [docPerPage, setDocPerPage] = useState(50);
      const [noOfPages, setNoOfPages] = useState();

   
    // function for pagination
           
      const handlePageChange = (pageNumber) => {
           setPageNo(pageNumber);
       };
       
       const handleDocsPerPage = (docsPageProp) => {
           setPageNo(1)
           setDocPerPage(docsPageProp);
       }


    useEffect(()=>{
      getVericationReport();
    },[pageNo, docPerPage]) 


   const getVericationReport = async()=>{
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/surveys/getVerifiedSurvey',{
      method:'POST',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({ 
        mobile:searchMobile, 
        date:date,
        documentsPerPage: docPerPage,
        pageNo: pageNo
      })
    }).then((res)=>res.json()).then((response)=>{
      setData(response);
      setNoOfPages(response?.noOfPages);
      setLoader(false);
      if(searchMobile?.length === 10){
        setDownload(true);
      }
    }).catch((err)=>{
      console.log("Err while getting users", err);
      setLoader(false);
    })
  }
   

  // --------------- Search Record By Mobile ------------------------------
    const searchByMobileValidate =()=>{
        let isValid = true;
        if(!searchMobile || searchMobile.length<10){
            isValid= false ;
            setSearchMobileErr(true);
        }
        return isValid;
    }

    const searchByMobile = async()=>{
        if(searchByMobileValidate()){
            getVericationReport();
        }
    }


    // --------------- Search Record By Verification Date ------------------------------
      const searchByDateValidate =()=>{
          let isValid = true;
          if(!date){
              isValid= false ;
              setDateErr(true);
          }
          return isValid;
      }

      const searchByDate = async()=>{
          if(searchByDateValidate()){
          getVericationReport();
          }
      }


       // ------------ Download Excel after searching by zone ---------------------
       const downloadVerficationExcel = async()=>{
         setExceLoader(true); 

        const keys_to_keep = ['propertyCode', 'propertyName', 'ownerName', 'ownerMobile', 'zonename', 
         'zoneno', 'verifiedByName', 'verifiedByMobile', 'verifiedDate', 'propertyType', 'surveyDate', 
          'propertyAddress', 'finalBusinessAddress' ];

            const ExcelData = data?.data?.map(e => {
                const obj = {};
                keys_to_keep.forEach(k => obj[k] = e[k])
                return obj;
              });

              const downloadExcel = (heading, fileName) => {
                let dataSet = [];
                dataSet = heading;
                const worksheet = XLSX.utils.json_to_sheet(dataSet);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                XLSX.writeFile(workbook, fileName);
              };
    
              downloadExcel(ExcelData, `${data?.data[0].verifiedByName}_Verification Report.xlsx`);
              setExceLoader(false);
            }
       


  return (
    <div className='verificationReport outletPadding'>
        <Container>
        <Row className='justify-content-between'>
              <Col md={3}>
                <Form.Label>Search by verification person mobile</Form.Label>
                  <div className='d-flex'>
                  <Form.Control placeholder='Enter Mobile No.' value={searchMobile} className='me-3'
                    onChange={(e)=>{ 
                      if (e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10) {
                          setSearchMobile(e.target.value);
                      } else if (e.target.value.length === 0) {
                          setSearchMobile(e.target.value);
                      }
                      e.target.value.length > 0 ? setSearchMobileErr(false) : setSearchMobileErr(true);
                    }} />
                  <Button className='primaryBtn' onClick={searchByMobile}>
                      <Icon.Search className='me-2' size={15}/>Search
                  </Button>
                  </div>
              { searchMobileErr ? <p className='errMsg'>Please enter valid mobile no.</p> : '' }
            </Col>

            <Col md={2} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
             <Form.Group>
                <Form.Label>Search by verification date</Form.Label> 
                <div className='d-flex align-items-center'>
                <Form.Control type='date' maz={new Date()} value={date} onChange={(e)=>setDate(moment(e.target.value).format('YYYY-MM-DD'))}/>
                 <Button className='primaryBtn ms-3' onClick={searchByDate}> 
                   <Icon.Search className='me-2'/>Search</Button>
                </div>
                {dateErr ? <p className='errMsg'>Please select valid date</p> :''}
              </Form.Group>  
            </Col>
        </Row>


            <Row className="outer-wrapper mx-auto mt-2">
                
                   { download &&
                      <div className='text-center' data-aos='zoom-in'>
                         {
                         excelLoader ? <center><Spinner variant='primary'/></center> :
                         <Button className='primaryBtn mx-auto' onClick={downloadVerficationExcel}>
                            <Icon.Download className='me-2'/>Download Excel</Button>
                          }
                            <p style={{fontSize:'14px', margin:'6px 0px'}}>
                          Click here to download excel report for <strong>{searchMobile}</strong></p>
                     </div>
                   }
                 
                    <div className="table-wrapper mt-2" style={{ maxHeight:'68vh' }}>
                        <table data-aos="fade-up" data-aos-delay="200">
                            <thead>
                                <th>Sr. No.</th>
                                <th>Verification Person</th>
                                <th>Verified Date</th>
                                <th>Survey Date</th>
                                <th>Zone</th>
                                <th>Property Code</th>
                                <th>Property Name</th>
                                <th>Property Type</th>
                                <th>Property Address</th>
                                <th>Final Business Address</th>
                                <th>Owner Name</th>
                                <th>Owner Mobile</th>
                            </thead>
                
                  { loader ? <Skeleton rows={10} cols={12}/> :
                      <tbody data-aos="fade-up">
                      {
                    data?.data!== null && data.data !== undefined && data?.data.length > 0 ? data?.data.map((itm, index) => {
                      const { propertyCode, propertyName, ownerName, ownerMobile, zonename, zoneno, 
                            verifiedByName, verifiedByMobile, verifiedDate, propertyType, surveyDate, 
                            propertyAddress, finalBusinessAddress } = itm

                            return (
                                <tr key={Math.random() * 999999999}>
                                    <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                    <td style={{width:'250px'}}>{verifiedByName + ' - ' + verifiedByMobile}</td>
                                    <td>{verifiedDate ?     verifiedDate :'-'}</td>
                                    <td>{surveyDate ? surveyDate :'-'}</td>
                                    <td>{zonename + ' - ' + zoneno}</td>
                                    <td>{propertyCode? propertyCode :'-'}</td>
                                    <td style={{width:'250px'}}>{propertyName? propertyName:'-'}</td>
                                    <td>{propertyType ? propertyType :'-'}</td>
                                    <td style={{width:'250px'}}>{propertyAddress ? propertyAddress :'-'}</td>
                                    <td style={{width:'250px'}}>{finalBusinessAddress ? finalBusinessAddress :'-'}</td>
                                    <td>{ownerName ? ownerName:'-'}</td>
                                    <td>{ownerMobile ? ownerMobile :'-'}</td>
                                </tr>
                            )
                         })
                  : <p className='noDataFound'>No Data Found</p>
                }
                </tbody>
            }  
                  </table>
               </div>
              
  
            <Row>
                <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
                    <h6 className='text-start mb-0'>
                        <Icon.People className='me-2'/>Total - <strong>{data?.count}</strong>
                    </h6>
                </Col>
                <Col md={8} className='d-flex justify-content-end p-md-0'>
                    <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange} 
                    onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage}/>
                </Col>
            </Row>
            </Row>
        </Container>
    </div>
  )
}

export default VerificationReport;