import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Spinner, Card, Button, Modal } from 'react-bootstrap';
import Pagination from '../Common/Pagination';
import * as Icon from 'react-bootstrap-icons';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import Lottie from 'react-lottie';
import imageScanner from '../../Assets/ImageScanner.json';
import { useSelector } from 'react-redux';
import ApproveShield from '../../Assets/ApproveShield.svg';
import RejectShield from '../../Assets/RejectShield.svg';
import moment from 'moment';


const SurveyImgVerification = () => {

    const userState = useSelector(state => state.surveylogin.userData?.data);

    const LottieOptions = {
        loop: true,
        autoplay: true,
        animationData: imageScanner,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }




    const ImageKeys = [
        { key: 'outsideImage', engLabel: 'Outside', marLabel:'बाहेरील', verifyKey: 'isOutsideImageVerified' },
        { key: 'insideImage', engLabel: 'Inside', marLabel:'आतील', verifyKey: 'isInsideImageVerified' },
        { key: 'sathaParvanaImage', engLabel: 'Satha Parvana', marLabel:'साठा परवाना', verifyKey: 'isSathaParvanaImageVerified' },
        { key: 'sathaShopActLicenseImage', engLabel: 'Satha ShopAct', marLabel:'साठा शॉपॲक्ट', verifyKey: 'isSathaShopActLicenseImageVerified' },
        { key: 'businessParvanaImage', engLabel: 'Business Parvana', marLabel:'बिझिनेस परवाना', verifyKey: 'isBusinessParvanaImageVerified' },
        { key: 'businessShopActLicenseImage', engLabel: 'Business Shopact', marLabel:'बिझिनेस शॉपॲक्ट', verifyKey: 'isBusinessShopActLicenseImageVerified' },
        { key: 'foodLicenseImage', engLabel: 'Food License', marLabel:'फूड लायसेन', verifyKey: 'isFoodLicenseImageVerified' },
        { key: 'fireNOCImage', engLabel: 'Fire NOC', marLabel:'फायर NOC', verifyKey: 'isFireNOCImageVerified' },
        { key: 'fireAnnualImage', engLabel: 'Fire Annual', marLabel:'फायर नूतनीकरण', verifyKey: 'isFireAnnualImageVerified' },
        { key: 'fireSafetyCertificateImage', engLabel: 'Fire Safety Certificate', marLabel:'फायर सेफ्टी सर्टिफिकेट', verifyKey: 'isFireSafetyCertificateImageVerified' },
        { key: 'electricMeterImage', engLabel: 'Electric Meter', marLabel:'इलेट्रीक मीटर', verifyKey: 'isElectricMeterImageVerified' },
        { key: 'fireExtinguisherEquipmentImage', engLabel: 'Fire Ext. Equipment', marLabel:'फायर उपकरणे', verifyKey: 'isFireExtinguisherEquipmentImageVerified' },
        { key: 'hazardousEquipmentImage', engLabel: 'Hazardous Equipment', marLabel:'धोकादायक यंत्रणा', verifyKey: 'isHazardousEquipmentImageVerified' },
        { key: 'atticImage', engLabel: 'Attic', marLabel:'पोटमळा', verifyKey: 'isAtticImageVerified' },
        { key: 'basementImage', engLabel: 'Basement', marLabel:'तळघर', verifyKey: 'isBasementImageVerified' },
        { key: 'residentialImage', engLabel: 'Residential', marLabel:'निवासी मालमत्ता', verifyKey: 'isResidentialImageVerified' }
    ]

    const [mahilaLsit, setMahilaList] = useState('');

    const [mahilaName, setMahilaName] = useState('');

    const [surveyDate, setSurveyDate] = useState('');

    const [loader, setLoader] = useState(false);
    const [surveyImages, setSurveyImages] = useState([]);

    const [loaderArr, setLoaderArr] = useState([])
    const [loadSurvey, setLoadSurvey] = useState()

    const [propertyTypeLoader, setPropertyTypeLoader] = useState(false);
    const [ownerMobileLoader, setOwnerMobileLoader] = useState(false);
    const [rentedMobileLoader, setRentedMobileLoader]  = useState(false);
    const [extraFields,setExtraFields] = useState() 

    // States for Approve-Reject survey models ----------------
    const [showVerify, setShowVerify] = useState(false);
    const [verifyPayload, setVerifyPayload] = useState('');
    const [notVerifiedReason, setNotVerifiedReason] = useState('');
    const [notVerifiedReasonErr, setNotVerifiedReasonErr] = useState(false);
    const [verifySurveyLoader, setVerifySurveyLoader] = useState(false);
    

    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(50);
    const [noOfPages, setNoOfPages] = useState();

    // function for pagination

    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    useEffect(() => {
        getAllMahilaList();
    }, [])

    const getAllMahilaList = async () => {
        if(userState?.userType_keyname === "mavim"){
           await fetch(process.env.REACT_APP_BASE_URL + '/users/getContractorsByMavim',{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                    mavimHeadId:userState?.user_id
                })
            }).then((res)=>res.json()).then((response)=>{
                    const result = response?.data?.map((itm) => {
                        return {
                            label: itm.name + " - " + itm.mobile, value: itm._id
                        }
                    });
                    setMahilaName({label: response?.data[0].name + " - " + response.data[0].mobile, value: response.data[0]._id});

                    setMahilaList(result);
                }).catch((Err)=>{
                    console.log("Err while getting mahila by ladies", Err);
                })
        }

        else{
            await fetch(process.env.REACT_APP_BASE_URL + '/users/getAllContractors', {
                method: 'GET'
            }).then((res) => res.json()).then((response) => {
                const result = response?.data?.map((itm) => {
                    return {
                        label: itm.name + " - " + itm.mobile, value: itm._id
                    }
                });
                setMahilaList(result);
            }).catch((Err) => {
                console.log("Error while getting contractors", Err);
            })
        }
       
    }
 
    useEffect(() => {
        setLoader(true);
        if(userState?.userType_keyname==='mavim' && mahilaName){
            getSurveyImagesByMahila();
        }
        else if(userState?.userType_keyname==='admin' || userState?.userType_keyname==="dataoperator"){
            getSurveyImagesByMahila();
        }
    }, [pageNo, docPerPage, mahilaName, surveyDate])


    const getSurveyImagesByMahila = async () => {
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/surveys/imageVerificationList', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                surveyById: mahilaName?.value,
                surveyDate: surveyDate,
                documentsPerPage: docPerPage,
                page: pageNo,
                // userRole:userState?.userType_keyname === 'mavim' ? "mavim" :''
            })
        }).then((res) => res.json()).then((response) => {
            setSurveyImages(response?.data);
            setNoOfPages(response?.data?.noOfPages);
            setLoader(false);
        }).catch((Err) => {
            console.log("Err while getting survey images by Mahila", Err);
        })
    }

    const openImageInNewTab = (imageUrl) => {
        window.open(imageUrl, '_blank');
    };

    const verifyImage = async (surveyId, verifyKey, marLabel, verifyStatus, index, surveyIndex) => {
        let arr = []
        for (let i = 0; i < ImageKeys.length; i++) {
            arr.push(false)
        }

            setExtraFields(surveyIndex);

        if(verifyKey === "isPropertyTypeVerified"){
            setPropertyTypeLoader(true);
        }
        else if(verifyKey === "isOwnerMobileVerified"){
            setOwnerMobileLoader(true);
        }
        else if(verifyKey === "isRentedPersonMobileVerified"){
            setRentedMobileLoader(true);
        }
        else{
            arr[index] = true
            setLoaderArr(arr)
            setLoadSurvey(surveyIndex); 
        }
        
        await fetch(process.env.REACT_APP_BASE_URL + '/verification/verifySurveyFields', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    surveyId: surveyId,
                    verifiedByMobile: userState?.mobile,
                    [verifyKey]: verifyStatus,
                })
            }).then((res)=>res.json()).then((response)=>{
                if (response?.message === 'Field Verified') {
                    arr[index] = false
                    setLoaderArr(arr)
                    setLoadSurvey();
                    setExtraFields('');
                    setPropertyTypeLoader(false);
                    setOwnerMobileLoader(false);
                    setRentedMobileLoader(false);
                    toast.success(`${marLabel} Image ${verifyStatus === true ? 'Verified' : 'Rejected'} !`);
                     let newArray = [...surveyImages.surveys]
                     newArray[surveyIndex] = {
                        ...newArray[surveyIndex],
                        [verifyKey]: verifyStatus,
                     }
                     console.log("Updated Index",newArray[surveyIndex])
                     setSurveyImages({...surveyImages,surveys:newArray})
                    // getSurveyImagesByMahila();
                }
            }).catch ((Err)=>{
                console.log('Err while verifying image fields', Err);
                arr[index] = false
                setLoaderArr(arr);
                    setPropertyTypeLoader(false);
                    setOwnerMobileLoader(false);
                    setRentedMobileLoader(false);
                    setExtraFields('');
            })
    }


// ---- Verify Survey (Bulk Images verify) --------------------------------------------------
    const handleVerifyModal = (surveyId, isVerified) => {
        setShowVerify(true);
        setVerifyPayload({ surveyId, isVerified, user_id: userState?.user_id });
    }

    const closeModal = () => {
        setShowVerify(false);
        setNotVerifiedReason('');
        setNotVerifiedReasonErr(false);
    }

    const verifySurvey = async () => {
        setVerifySurveyLoader(true);
        if (verifyPayload?.isVerified === false && notVerifiedReason.length === 0) {
            // Set errMsg to true when notVerifiedReason is empty
            setNotVerifiedReasonErr(true);
            setVerifySurveyLoader(false);
            return;
        }
        await fetch(process.env.REACT_APP_BASE_URL + '/verification/verifySurvey', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ...verifyPayload,
                notVerifiedReason: notVerifiedReason
            })
        }).then((res) => res.json()).then((response) => {
            if (response.message === "Survey Verified") {
                toast.success(`Survey ${verifyPayload?.isVerified === true ? 'Approved' : 'Rejected'} Successfully !`);
            }
            setVerifySurveyLoader(false);
            closeModal(false);
            getSurveyImagesByMahila();
        }).catch((Err) => {
            console.log("Err while verifying survey", Err);
            setVerifySurveyLoader(false);
        })
    }


    return (
        <div className='SurveyImgVerification outletPadding'>
            <ToastContainer />
            <Container>
                <Row className='justify-content-between mb-2'>
                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Search by mahila mobile or name</Form.Label>
                            <Select value={mahilaName} options={Array.isArray(mahilaLsit) ? mahilaLsit : []} isMulti={false}
                                placeholder={<div>Enter name or mobile</div>}
                                onChange={(selected) => { setMahilaName(selected) }} />
                        </Form.Group>
                    </Col>

                    <Col md={2} className='mb-4'>
                        <Form.Group>
                            <Form.Label>Filter By Date</Form.Label>
                            <Form.Control type='date' maz={new Date()} value={surveyDate} onChange={(e) => setSurveyDate(moment(e.target.value).format('YYYY-MM-DD'))} />
                        </Form.Group>
                    </Col>
                </Row>

                {
                    loader ? <center><Spinner variant='primary' /></center> :
                        <>
                            <Row className='imgCardParent'>
                                {
                                    surveyImages?.surveys !== null && surveyImages?.surveys !== undefined
                                        && surveyImages?.surveys?.length > 0 ? surveyImages?.surveys?.map((itm, surveyIndex) => {

                                            return (
                                                <Col md={10} className='mb-4' key={surveyIndex}>
                                                    <Card className={itm?.isVerified === true ? 'approved'
                                                        : itm?.isVerified === undefined ? 'nothing' : 'rejected'}>
                                                        <Row>
                                                            {ImageKeys.map((category, Imageindex) =>

                                                                itm[category.key] && itm[category.key].length>0 ? ( 

                                                                    itm[category.key].length === 1 ?
                                                                        itm[category.key].map((img, index) => {

                                                                            const isImage = img.endsWith('.jpg') || img.endsWith('.jpeg') || img.endsWith('.png');
                                                                            const isVideo = img.endsWith('.mp4') || img.endsWith('.mov') || img.endsWith('.avi');

                                                                            return (

                                                                                <Col xs={6} md={2} key={index} className='mb-0'>
                                                                                    <p>{`${category.marLabel} ${itm[category.key].length > 1 ? index + 1 : ''}`}</p>
                                                                                     {
                                                                                        loaderArr.length > 0 && surveyIndex === loadSurvey && loaderArr[Imageindex] ? <Lottie options={LottieOptions} width={120} height={120}/> :
                                                                                            <div className='imgBlock'>
                                                                                                {isImage && <img src={img} alt={`${category.marLabel} ${index + 1}`} onClick={() => openImageInNewTab(img)}
                                                                                                    className={itm[category.verifyKey] === true ? 'approved'
                                                                                                        : itm[category.verifyKey] === undefined ? '' : 'rejected'} />}
                                                                                                {isVideo && <video className={itm[category.verifyKey] === true ? 'approved'
                                                                                                    : itm[category.verifyKey] === undefined ? '' : 'rejected'}> <source src={img} type="video/mp4"  /> </video>}
                                                                                                {isVideo && <span className='PlayBtn'><Icon.PlayCircleFill size={34} onClick={() => openImageInNewTab(img)} /></span>}
                                                                                                <span className='Reject' onClick={() => verifyImage(itm?.surveyId, category.verifyKey, category.marLabel, false, Imageindex, surveyIndex)}>
                                                                                                    <Icon.XCircleFill size={18} />
                                                                                                </span>
                                                                                                <span className='Approve' onClick={() => verifyImage(itm?.surveyId, category.verifyKey, category.marLabel, true, Imageindex, surveyIndex)}>
                                                                                                    <Icon.CheckCircleFill size={18} />
                                                                                                </span>
                                                                                            </div>
                                                                                     }
                                                                                </Col>
                                                                            )
                                                                        })
                                                                        :

                                                                // Map for multiple image in single col -----------------------
                                                                        <Col xs={itm[category.key.length * 4]} md={itm[category.key].length * 2} className='mb-0'>
                                                                            <Row>
                                                                                {
                                                                                    itm[category.key].map((img, index) => {

                                                                                        const isImage = img.endsWith('.jpg') || img.endsWith('.jpeg') || img.endsWith('.png');
                                                                                        const isVideo = img.endsWith('.mp4') || img.endsWith('.mov') || img.endsWith('.avi');

                                                                                        return (
                                                                                            loaderArr.length > 0 && surveyIndex === loadSurvey && loaderArr[Imageindex] ? <Lottie options={LottieOptions} width={120} height={120}/> :
                                                                                            <Col xs={6} md={6} key={index}>
                                                                                                <p>{`${category.marLabel} ${itm[category.key].length > 1 ? index + 1 : ''}`}</p>
                                                                                                <div className='imgBlock'>
                                                                                                    {isImage && <img src={img} alt={`${category.marLabel} ${index + 1}`} onClick={() => openImageInNewTab(img)}
                                                                                                        className={itm[category.verifyKey] === true ? 'approved'
                                                                                                            : itm[category.verifyKey] === undefined ? '' : 'rejected'} />}
                                                                                                    {isVideo && <video  className={itm[category.verifyKey] === true ? 'approved'
                                                                                                        : itm[category.verifyKey] === undefined ? '' : 'rejected'}> <source src={img} type="video/mp4" /> </video>}
                                                                                                    {isVideo && <span className='PlayBtn'><Icon.PlayCircleFill size={34} onClick={() => openImageInNewTab(img)} /></span>}
                                                                                                </div>
                                                                                            </Col>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <div className='d-flex justify-content-center mt-0'>
                                                                                    <span className='multiReject me-3' onClick={() => verifyImage(itm?.surveyId, category.verifyKey, category.marLabel, false, Imageindex, surveyIndex)}>
                                                                                        <Icon.XCircleFill size={18} />
                                                                                    </span>
                                                                                    <span className='multiApprove' onClick={() => verifyImage(itm?.surveyId, category.verifyKey, category.marLabel, true, Imageindex, surveyIndex)}>
                                                                                        <Icon.CheckCircleFill size={18} />
                                                                                    </span>
                                                                                </div>
                                                                            </Row>

                                                                        </Col>
                                                                ) : null
                                                                        )}
                                                        </Row>

                                                        <div className='mt-0 extraFields'>
                                                            {
                                                               surveyIndex === extraFields && propertyTypeLoader ? <Spinner variant='primary'/> :
                                                                <span>
                                                                <h6 style={{color: itm?.isPropertyTypeVerified === true ? 'green' : 
                                                                    itm?.isPropertyTypeVerified === undefined ?  '#333333' : 'red' }}>
                                                                        Property Type</h6>
                                                                <p>{itm?.propertyType}</p>
                                                                <div className='mt-2'>
                                                                    <Icon.XCircleFill size={22} className='me-3' color="red" onClick={() => verifyImage(itm?.surveyId, "isPropertyTypeVerified", "Property Type", false, '1', surveyIndex)}/>
                                                                    <Icon.CheckCircleFill size={22} color="green" onClick={() => verifyImage(itm?.surveyId, "isPropertyTypeVerified", "Property Type", true, '1', surveyIndex)}/>
                                                                </div>
                                                            </span>
                                                            }

                                                            {
                                                               surveyIndex === extraFields && ownerMobileLoader ? <Spinner variant='primary'/> :
                                                                <span>
                                                                    <h6 style={{color: itm?.isOwnerMobileVerified === true ? 'green' : 
                                                                        itm?.isOwnerMobileVerified === undefined ?  '#333333' : 'red'   }}>Owner Mobile</h6>
                                                                    <p>{itm?.ownerMobile}</p>
                                                                    <div className='mt-2'>
                                                                        <Icon.XCircleFill size={22} className='me-3' color="red" onClick={() => verifyImage(itm?.surveyId, "isOwnerMobileVerified", "Owner Mobile", false, '1', surveyIndex)}/>
                                                                        <Icon.CheckCircleFill size={22} color="green" onClick={() => verifyImage(itm?.surveyId, "isOwnerMobileVerified", "Owner Mobile", true, '1', surveyIndex)}/>
                                                                    </div>
                                                                </span>
                                                            }
                                                           
                                                            
                                                            {
                                                                itm?.rentedPersonMobile &&
                                                                <span>
                                                                    {
                                                                       surveyIndex === extraFields && rentedMobileLoader ? <Spinner variant='primary'/> :
                                                                        <>
                                                                          <h6 style={{color: itm?.isRentedPersonMobileVerified === true ? 'green' : 
                                                                            itm?.isRentedPersonMobileVerified === undefined ?  '#333333' : 'red'   }}>Rented Mobile</h6>
                                                                            <p>{itm?.rentedPersonMobile}</p>
                                                                        <div className='mt-2'>
                                                                            <Icon.XCircleFill size={22} className='me-3' color="red" onClick={() => verifyImage(itm?.surveyId, "isRentedPersonMobileVerified", "Rented Mobile", false, '1', surveyIndex)}/>
                                                                            <Icon.CheckCircleFill size={22} color="green" onClick={() => verifyImage(itm?.surveyId, "isRentedPersonMobileVerified", "Rented Mobile", true, '1', surveyIndex)}/>
                                                                        </div>
                                                                     </>
                                                                    }
                                                                </span>
                                                            }
                                                        </div>

                                                        <div className='d-flex justify-content-center mt-4'>
                                                            <button className='rejectBtn me-3'
                                                                onClick={() => handleVerifyModal(itm?.surveyId, false)} >
                                                                <Icon.XCircleFill className='me-2' size={12} />Reject
                                                            </button>
                                                            <button className='approveBtn'
                                                                onClick={() => handleVerifyModal(itm?.surveyId, true)}>
                                                                <Icon.CheckCircleFill className='me-2' size={13} />Approve
                                                            </button>
                                                        </div>


                                                    </Card>
                                                </Col>
                                            )
                                        })
                                        : <p>No Images Found</p>
                                }
                            </Row>

                            <Row>
                                <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
                                    <h6 className='text-start mb-0'>
                                        <Icon.People className='me-2' />Total - <strong>{surveyImages?.count}</strong>
                                    </h6>
                                </Col>
                                <Col md={8} className='d-flex justify-content-end p-md-0'>
                                    <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
                                        onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
                                </Col>
                            </Row>
                        </>
                }

            </Container>


            <Modal size='md' show={showVerify} backdrop='static' keyboard={false} centered onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                          verifyPayload?.isVerified === true ?
                            <h5 className='mb-0'>
                                <Icon.CheckCircleFill size={20} className='me-2' color='green' />Approve Survey</h5> :
                            <h5 className='mb-0'><Icon.XCircleFill size={20} className='me-2' color='red' />Reject Survey</h5>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    {
                        verifyPayload?.isVerified === true ?
                            <div data-aos='zoom-in'>
                                <img src={ApproveShield} alt="Aprrove Survey" width={80} height={80} />
                                <h4 style={{ fontWeight: '600', marginTop: '6px' }}>Are you sure to approve this survey ?</h4>
                            </div>
                            :
                            <div data-aos='zoom-in'>
                                <img src={RejectShield} alt="Aprrove Survey" width={80} height={80} />
                                <h4 style={{ fontWeight: '600', marginTop: '6px' }}>Are you sure to reject this survey ?</h4>
                                <Form.Group className='text-start mt-3 px-3'>
                                    <Form.Label><span>*</span> Enter reject reason to continue</Form.Label>
                                    <Form.Control placeholder='Enter reason' value={notVerifiedReason}
                                        onChange={(e) => setNotVerifiedReason(e.target.value)} />
                                    {notVerifiedReasonErr ? <p className='errMsg'>Please enter reason for rejection</p> : ''}
                                </Form.Group>
                            </div>
                    }
                </Modal.Body>

                <Modal.Footer>
                    {
                        verifySurveyLoader ? <Spinner variant='primary' /> :
                            <>
                                <Button className='primaryBtn me-3' onClick={verifySurvey}>
                                    <Icon.HandThumbsUp className='me-1' />Yes</Button>
                                <Button className='secondaryBtn' onClick={closeModal}>
                                    <Icon.HandThumbsDown className='me-1' />No</Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default SurveyImgVerification;