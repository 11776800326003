import React, { useState } from "react";
import { Accordion, Button, Spinner } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../Auth/LoginSlice";
import { setActiveKey } from "../Common/ActiveKeySlice";
import PCMC from '../../Assets/PCMC-LOGO.png';


const Sidebar = () => {

  const userState = useSelector(state => state.surveylogin.userData.data);
  const activeKeyRedux = useSelector(state => state.surveyActiveData?.activeNumberKey);

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout());
    dispatch(setActiveKey(1));
    navigate('/');
    // storage.removeItem('persist:root')
    setLoader(false);
  }

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(setActiveKey({ activeKey: activeKey, subActiveKey: subActiveKey, activeTab: tabName }));
  };


  return (
    <>
      <div className="sidenav-lg d-flex justify-content-between flex-column" >
        <div>
          <div className="logoBg mb-4">
              <img src={PCMC} alt="PCMC Survey" width={70} />
              <hr/>
            </div>

          {
           userState?.userType_keyname==="admin" &&
            <Accordion flush activeKey={activeKeyRedux?.activeKey}>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Dashboard"); navigate('/dashboard'); }} className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseDoorFill className='me-2' /> : <Icon.HouseDoor className='me-2' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Manage Users"); navigate("/dashboard/manage-users"); }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.PersonFill className='me-2' /> : <Icon.Person className='me-2' />}Manage Users</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Mahilawise Report"); navigate("/dashboard/mahilawise-report"); }} className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 3 ? <Icon.PersonCircle className='me-2' /> : <Icon.PersonCircle className='me-2' />}Mahilawise Report</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={7} onClick={() => { handleAccordionChange(7, 7, "Fire Officer Report"); navigate("/dashboard/fireofficer-report"); }} className={activeKeyRedux?.activeKey === 7 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 7 ? <Icon.Fire className='me-2' /> : <Icon.Fire className='me-2' />}Fire Officer Report</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={11} onClick={() => { handleAccordionChange(11, 11, "Mavim Report"); navigate("/dashboard/mavim-report"); }} className={activeKeyRedux?.activeKey === 11 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 7 ? <Icon.PersonBoundingBox className='me-2' /> : <Icon.PersonBoundingBox className='me-2' />}Mavim Report</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={4} onClick={() => { handleAccordionChange(4, 4, "Manage Surveys"); navigate("/dashboard/manage-surveys"); }} className={activeKeyRedux?.activeKey === 4 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 4 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2' />}Manage Surveys</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={5} onClick={() => { handleAccordionChange(5, 5, "Weekly Report"); navigate("/dashboard/daywise-report"); }} className={activeKeyRedux?.activeKey === 5 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 5 ? <Icon.CalendarRangeFill className='me-2' /> : <Icon.CalendarRange className='me-2' />}Weekly Report</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={6} onClick={() => { handleAccordionChange(6, 6, "Verification Report"); navigate("/dashboard/verification-report"); }} className={activeKeyRedux?.activeKey === 6 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 6 ? <Icon.ShieldFillCheck className='me-2' /> : <Icon.ShieldCheck className='me-2' />}Verification Report</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={8} onClick={() => { handleAccordionChange(8, 8, "Nearby Report"); navigate("/dashboard/nearby-report"); }} className={activeKeyRedux?.activeKey === 8 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 8 ? <Icon.GeoAltFill className='me-2' /> : <Icon.GeoAlt className='me-2' />}Nearby Report</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={9} onClick={() => { handleAccordionChange(9, 9, "Survey Images"); navigate("/dashboard/survey-images"); }} className={activeKeyRedux?.activeKey === 9 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 9 ? <Icon.ImageFill className='me-2' /> : <Icon.Image className='me-2' />}Survey Images</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={10} onClick={() => { handleAccordionChange(10, 10, "Survey Image Verification"); navigate("/dashboard/survey-image-verification"); }} className={activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 10 ? <Icon.CartCheckFill className='me-2' size={17}/> : <Icon.CartX className='me-2' size={17} />}Image Verification</Accordion.Header>
              </Accordion.Item>
            </Accordion>
          }



    {
           userState?.userType_keyname==="mavim" &&
            <Accordion flush activeKey={activeKeyRedux?.activeKey}>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Dashboard"); navigate('/dashboard'); }} className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseDoorFill className='me-2' /> : <Icon.HouseDoor className='me-2' />}Home</Accordion.Header>
              </Accordion.Item>
  
              <Accordion.Item eventKey={4} onClick={() => { handleAccordionChange(4, 4, "Manage Surveys"); navigate("/dashboard/manage-surveys"); }} className={activeKeyRedux?.activeKey === 4 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 4 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2' />}Manage Surveys</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={9} onClick={() => { handleAccordionChange(9, 9, "Survey Images"); navigate("/dashboard/survey-images"); }} className={activeKeyRedux?.activeKey === 9 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 9 ? <Icon.ImageFill className='me-2' /> : <Icon.Image className='me-2' />}Survey Images</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={10} onClick={() => { handleAccordionChange(10, 10, "Survey Image Verification"); navigate("/dashboard/survey-image-verification"); }} className={activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 10 ? <Icon.CartCheckFill className='me-2' size={17}/> : <Icon.CartX className='me-2' size={17} />}Image Verification</Accordion.Header>
              </Accordion.Item>
            </Accordion>
          }


        {
           userState?.userType_keyname ==="dataoperator" &&
            <Accordion flush activeKey={activeKeyRedux?.activeKey}>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Dashboard"); navigate('/dashboard'); }} className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseDoorFill className='me-2' /> : <Icon.HouseDoor className='me-2' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={9} onClick={() => { handleAccordionChange(9, 9, "Survey Images"); navigate("/dashboard/survey-images"); }} className={activeKeyRedux?.activeKey === 9 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 9 ? <Icon.ImageFill className='me-2' /> : <Icon.Image className='me-2' />}Survey Images</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={10} onClick={() => { handleAccordionChange(10, 10, "Survey Image Verification"); navigate("/dashboard/survey-image-verification"); }} className={activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 10 ? <Icon.CartCheckFill className='me-2' size={17}/> : <Icon.CartX className='me-2' size={17} />}Image Verification</Accordion.Header>
              </Accordion.Item>
            </Accordion>
          }
      </div>

        <div className="pb-4 ps-2">
          <Button className='LogoutBtn w-100' onClick={handleLogout}>{loader ? <Spinner animation="border" variant="light" className='spinner' /> : <><Icon.BoxArrowLeft size={18} className='me-2' />Logout</>}</Button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;




