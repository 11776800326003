import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import mavims from '../../Assets/Mavims.jpg';

const Home2 = () => {

  const userState = useSelector(state => state.surveylogin.userData.data);

    var today = new Date();
    var curHr = today.getHours(); 

  return (
    <div className='Home outletPadding'>
        <Container style={{height:'90vh', overflow:'hidden'}}>
            <Row className='h-100 text-center p-0'>
              <div data-aos="zoom-in" data-aos-delay="300">
              <h5 className='mb-0' style={{fontWeight:'500'}}>Hello {userState?.userType_name}, { curHr < 12 ? <>Good Morning<Icon.BrightnessHighFill className='ms-2 me-1' /></>
                    : curHr < 18 ? <>Good Afternoon<Icon.BrightnessAltHighFill className='ms-2 me-1' size={20} /></> :
                      <>Good Evening<Icon.MoonStars className='ms-2 me-1' /> </>}
              </h5>
              <h1 style={{fontWeight:'600'}}>Welcome to dashboard</h1>
              </div>
              <Col md={12} className='align-self-end' data-aos="fade-up" data-aos-delay="400" data-aos-offset="0">
                <img src={mavims} alt="mavims" className='img-fluid'/>
              </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Home2