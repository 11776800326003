import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Card, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import Search from '../../Assets/Search.svg'
import Surveys from '../../Assets/Surveys.svg';
import PartlyDoc from '../../Assets/PartlySubmittedDoc.svg';
import FullyChecked from '../../Assets/FullySubmitChecked.svg';
import OwnerMobile from '../../Assets/OwnerMobile.svg';
import RentedMobile from '../../Assets/RentedShop.svg';
import Rental from '../../Assets/Rental.svg';
import Goal from '../../Assets/Goal.svg';
import Licesne from '../../Assets/License.svg';
import Pagination from '../Common/Pagination';
import Skeleton from '../Common/Skeleton';


const ViewContractors = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [loader, setLoader] = useState(false);



    const [data, setData] = useState('');
    
    const [repeated, setRepeated] = useState('');
    const [repeatedLoader, setRepeatedLoader] = useState(false);

    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(50);
    const [noOfPages, setNoOfPages] = useState();


// function for pagination ----------
       
  const handlePageChange = (pageNumber) => {
       setPageNo(pageNumber);
   };
   
   const handleDocsPerPage = (docsPageProp) => {
       setPageNo(1)
       setDocPerPage(docsPageProp);
   }


    useEffect(()=>{
        getSingleSurvey();
    },[])

    useEffect(()=>{
      getRepeatedData();
  },[pageNo, docPerPage])

    const getSingleSurvey = async()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/dashboard/contractorCountGraph',{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({ mobile:location?.state?.mobile})
        }).then(res=>res.json()).then((response)=>{
              setData(response);
              setLoader(false);
            }).catch((Err)=>{
              console.log("Err while getting Single Survey Graph", Err);
              setLoader(false);
            })
    }

    const getRepeatedData =  async()=>{
      setRepeatedLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + '/surveys/repeatedOwnerNumbers', {
        method:'POST',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify({ 
          surveyById:location?.state?._id,
          documentsPerPage: docPerPage,
          page: pageNo 
        })
        }).then(res=>res.json()).then((response)=>{
              setRepeated(response?.data);
              setNoOfPages(response?.data?.noOfPages);
              setRepeatedLoader(false);
            }).catch((Err)=>{
              console.log("Err while getting Repeated Survey Count", Err);
              setRepeatedLoader(false);
            })
    }


  return (
    <div className='ViewContractors outletPadding'>
        <Container>
            <Button className='primaryBtn' onClick={()=>navigate(-1)}>
                <Icon.ArrowLeft className='me-2'/>Back
            </Button>

            <h3 className='text-center'>Contractors Report</h3>
            <h6 className='text-center my-3'>Name- {location?.state?.name}</h6>

           
    { loader ? <center><Spinner variant='primary'/></center> :
      <>
        <Row className='mt-3 justify-content-center'>
          <h6 className='headLabel'>General Information Stats</h6>
          <Col md={3} data-aos="fade-up" data-aos-delay='300' className='mb-4'>
              <Card>
                <Card.Body>
                <img src={Search} alt="Partly Submitted"/>
                <div className='data'>
                  <h4>{data?.onlySearchCount}</h4>
                  <h6>Only Searched</h6>
                </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} data-aos="fade-up" data-aos-delay='300' className='mb-4'>
              <Card>
                <Card.Body>
                <img src={PartlyDoc} alt="Partly Submitted"/>
                <div className='data'>
                  <h4>{data?.partlySubmitCount}</h4>
                  <h6>Partly Submitted</h6>
                </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
              <Card>
                <Card.Body>
                <img src={Surveys} alt="Fully Submitted"/>
                <div className='data'>
                  <h4>{data?.fullySubmitCount}</h4>
                  <h6>Fully Submitted</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
              <Card>
                <Card.Body>
                <img src={FullyChecked} alt="Details Not Given"/>
                <div className='data'>
                  <h4>{data?.detailsNotGivenCount}</h4>
                  <h6>Details Not Given</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
              <Card>
                <Card.Body className='100'>
                <img src={FullyChecked} alt="Fully Checked"/>
                <div className='data'>
                  <h4>{data?.propertyCodeExistsCount}</h4>
                  <h6>With Property Code</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
              <Card className='h-100'>
                <Card.Body>
                <img src={FullyChecked} alt="Withouy Property Code"/>
                <div className='data'>
                  <h4>{data?.propertyCodeNotExistsCount}</h4>
                  <h6>Without Property Code</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
              <Card>
                <Card.Body>
                <img src={FullyChecked} alt="Details Not Given"/>
                <div className='data'>
                  <h4>{data?.detailsNotGivenCount}</h4>
                  <h6>Details Not Given</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
              <Card>
                <Card.Body className='100'>
                <img src={FullyChecked} alt="Fully Checked"/>
                <div className='data'>
                  <h4>{data?.propertyCodeExistsCount}</h4>
                  <h6>With Property Code</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
              <Card className='h-100'>
                <Card.Body>
                <img src={FullyChecked} alt="Withouy Property Code"/>
                <div className='data'>
                  <h4>{data?.propertyCodeNotExistsCount}</h4>
                  <h6>Without Property Code</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>

        </Row> 


        <Row className='justify-content-center mt-3'>
          <h6 className='headLabel'>सामान्य माहिती</h6>
          
            <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={OwnerMobile} alt="Owner Mobile Captured"/>
              <div className='data'>
                <h4>{data?.ownerMobileCaptured}</h4>
                <h6>मूळ मालमत्तेच्या मालकाचा संपर्क क्रमांक</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={RentedMobile} alt="Lessee Mobile Captured"/>
              <div className='data'>
                <h4>{data?.rentedPersonMobileCaptured}</h4>
                <h6>भाडेकरी/व्यवसाय धारकाचे संपर्क क्रमांक</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Rental} alt="Total Rental Properties"/>
              <div className='data'>
                <h4>{data?.rentedCount}</h4>
                <h6>व्यवसाय चालक मूळ मालक आहे कि भाडे तत्त्वावर दिली आहे ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className='justify-content-center mt-3'>
        <h6 className='headLabel'>परवाना संबंधित माहिती</h6>
          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
              <Card className='h-100'>
                <Card.Body>
                <img src={Goal} alt="Total Businesses"/>
                <div className='data'>
                  <h4>{data?.businessCount}</h4>
                  <h6>व्यवसाय परवाना संबंधित माहिती</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
              <Card className='h-100'>
                <Card.Body>
                <img src={Licesne} alt="Total Businesses Parvana"/>
                <div className='data'>
                  <h4>{data?.businessParvanaCount}</h4>
                  {/* <h6>Total Business Parvana</h6> */}
                  <h6>व्यवसाय परवाना घेतला आहे का ?</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Total Shopact License"/>
              <div className='data'>
                <h4>{data?.businessShopActLicenseCount}</h4>
                {/* <h6>Total Business Shopact</h6> */}
                <h6>शॉप अँक्ट लायसन्स घेतले आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Total Food License"/>
              <div className='data'>
                <h4>{data?.foodLicenseCount}</h4>
                {/* <h6>Total Food Licenses</h6> */}
                <h6>अन्न औषध प्रशासन परवाना घेतला आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Total Satha License"/>
              <div className='data'>
                <h4>{data?.sathaCount}</h4>
                {/* <h6>Total Satha Count</h6> */}
                <h6>साठा परवाना संबंधित माहिती</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Total satha Parvana"/>
              <div className='data'>
                <h4>{data?.sathaParvanaCount}</h4>
                {/* <h6>Total Satha Parwana</h6> */}
                <h6>मनपा मार्फत साठा परवाना घेतला आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Total Satha Shopact"/>
              <div className='data'>
                <h4>{data?.sathaShopActLicenseCount}</h4>
                {/* <h6>Total Satha Shopact</h6> */}
                <h6>शॉप अँक्ट लायसन्स घेतले आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </>
    }

          <div className="outer-wrapper mx-auto mt-4">
                    <div className="table-wrapper" style={{maxHeight:'66vh'}}>
                        <table>
                            <thead>
                                <th>Sr. No.</th>
                                <th>Owner Mobile</th>
                                <th>Repeated Count</th>
                                <th>Owner Names</th>
                            </thead>

                { repeatedLoader ? <Skeleton rows={10} cols={4}/> :
                    repeated?.surveys!== null && repeated.surveys !== undefined && repeated?.surveys.length > 0 ? repeated?.surveys.map((itm, index) => {

                      const { ownerMobile, repeatCount, ownerNames} = itm

                        return (
                          <tr key={Math.random() * 999999999}>
                            <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                            <td style={{width:'18vw'}}>{ownerMobile}</td>
                            <td>{repeatCount}</td>
                            <td>{ownerNames?.map((row) => { return <tr>{row}</tr> })}</td>
                          </tr>
                      )
                    })
                  : <p className='noDataFound'>No Data Found</p>
                }   
                  </table>
              </div>
        <Row>
            <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>
                <Icon.People className='me-2'/>Total - <strong>{repeated?.count}</strong>
            </h6>
            </Col>
            <Col md={8} className='d-flex justify-content-end'>
          <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange} 
        onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage}/> 
            </Col>
        </Row>

            </div>

        </Container>
    </div>
  )
}

export default ViewContractors;