import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { useState } from "react";
import { Button, ButtonGroup, Col, Container, Form, Row, Spinner, Card, InputGroup } from "react-bootstrap";
import axios from 'axios';
import * as Icon from 'react-bootstrap-icons';
import ReactS3 from "../../Utils/ReactS3";
import Logo from '../../Assets/PCMC-LOGO.png';

const SurveyForm = () => {

    const navigation = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [selectedLanguage, setSelectedLanguage] = useState('english');

    useEffect(() => {
        getLebal()
        getSubpropertyTypeList()
        getAwsCredentials()
        getInitialData()
    }, [])

    const [loader, setloader] = useState(false);
    const [label, setlabel] = useState("");
    const [subpropertyType, setsubpropertyType] = useState([]);
    const [awsCredential, setAwsCredential] = useState("")
    const [initialData, setInitialData] = useState({})

    const [buildingFloors, setbuildingFloors] = useState("");
    const [buildingFloorsError, setbuildingFloorsError] = useState("");


    const [propertyFloor, setpropertyFloor] = useState("");
    const [propertyFloorError, setpropertyFloorError] = useState("");

    const [propertyAreaFoot, setpropertyAreaFoot] = useState("");
    const [propertyAreaFootError, setpropertyAreaFootError] = useState("");

    const [propertyAreaMeter, setpropertyAreaMeter] = useState("");
    const [propertyAreaMeterError, setpropertyAreaMeterError] = useState("");

    const [propertyWorkPlace, setpropertyWorkPlace] = useState("");
    const [propertyWorkPlaceError, setpropertyWorkPlaceError] = useState("");


    const [rentedPersonName, setrentedPersonName] = useState("");
    const [rentedPersonNameError, setrentedPersonNameError] = useState("");

    const [rentedPersonMobile, setrentedPersonMobile] = useState("");
    const [rentedPersonMobileError, setrentedPersonMobileError] = useState("");


    const [businessName, setBusinessName] = useState("");

    const [addressLandmark, setaddressLandmark] = useState("");
    const [addressFloor, setaddressFloor] = useState("");
    const [addressRoadColony, setaddressRoadColony] = useState("");

    const [subPropertyType, setsubPropertyType] = useState("");
    const [subPropertyTypeId, setsubPropertyTypeId] = useState("");
    const [ownerName, setownerName] = useState("");
    const [businessAddress, setbusinessAddress] = useState("");
    const [finalBusinessAddress, setfinalBusinessAddress] = useState("");
    const [ownerMobile, setownerMobile] = useState("");
    const [ownerEmail, setownerEmail] = useState("");
    const [isRented, setisRented] = useState(false);

    const [businessStartDate, setbusinessStartDate] = useState("");

    // form two
    const [isSatha, setisSatha] = useState(false);
    const [isVyavsay, setisVyavsay] = useState(false);
    const [isSathaShopActLicense, setisSathaShopActLicense] = useState(false);
    const [isBusinessParvana, setisBusinessParvana] = useState(false);
    const [isBusinessShopActLicense, setisBusinessShopActLicense] = useState(false);

    // form three
    const [outsideImage, setoutsideImage] = useState("");
    const [outsideImageError, setoutsideImageError] = useState("");
    const [isFireNOC, setisFireNOC] = useState("");
    const [isResidentialPurpose, setisResidentialPurpose] = useState("");
    const [isFireExtinguisherEquipment, setisFireExtinguisherEquipment] = useState("");
    const [isAttic, setisAttic] = useState("");
    const [isBasement, setisBasement] = useState("");

    const getLebal = async () => {
        await axios
            .get(process.env.REACT_APP_BASE_URL + "/labels/getLabels")
            .then((res) => {
                if (res.data.data) {
                    setlabel(res.data.data);
                }
            })
            .catch((err) => {
                setloader(false);
            });
    };

    const getInitialData = () => {
        setloader(true);
        try {
            axios.post(process.env.REACT_APP_BASE_URL + "/ownerform/getSurveyBySurveyId", {
                surveyId: queryParams.get('surveyId'),
            }).then((res) => {
                const { buildingFloors, propertyFloor, propertyAreaFoot, propertyAreaMeter, propertyWorkPlace, subPropertyTypeId,
                    businessName, ownerName, finalBusinessAddress, addressRoadColony, addressLandmark, ownerMobile, ownerEmail,
                    isRented, rentedPersonName, rentedPersonMobile, businessStartDate, isSatha, isVyavsay, isSathaShopActLicense,
                    isBusinessParvana, isBusinessShopActLicense,
                    outsideImage, isFireNOC, isResidentialPurpose, isFireExtinguisherEquipment, isAttic, isBasement } = res?.data?.data

                setbuildingFloors(buildingFloors)
                setpropertyFloor(propertyFloor)
                setpropertyAreaFoot(propertyAreaFoot)
                setpropertyAreaMeter(propertyAreaMeter)
                setpropertyWorkPlace(propertyWorkPlace)
                setsubPropertyTypeId(subPropertyTypeId)
                setBusinessName(businessName)
                setownerName(ownerName)
                setfinalBusinessAddress(finalBusinessAddress)
                setaddressRoadColony(addressRoadColony)
                setaddressLandmark(addressLandmark)
                setownerMobile(ownerMobile)
                setownerEmail(ownerEmail)
                setisRented(isRented)
                setrentedPersonName(rentedPersonName)
                setrentedPersonMobile(rentedPersonMobile)
                setbusinessStartDate(businessStartDate)
                setisSatha(isSatha)
                setisVyavsay(isVyavsay)
                setisSathaShopActLicense(isSathaShopActLicense)
                setisBusinessParvana(isBusinessParvana)
                setisBusinessShopActLicense(isBusinessShopActLicense)
                setoutsideImage(outsideImage)
                setisFireNOC(isFireNOC)
                setisResidentialPurpose(isResidentialPurpose)
                setisFireExtinguisherEquipment(isFireExtinguisherEquipment)
                setisAttic(isAttic)
                setisBasement(isBasement)


                setloader(false);
            })
        } catch (error) {
            setloader(false);
            console.log("error", error);
        }
    }

    const getSubpropertyTypeList = async () => {
        await axios
            .get(process.env.REACT_APP_BASE_URL + "/spt/getAllSubPropertyType")
            .then((res) => {
                setsubpropertyType(res.data.data);
            })
            .catch((err) => {
                console.log("errrr", err);
            });
    }

    const getLabelName = (field, page) => {
        const languageObject = selectedLanguage === 'marathi' ? page === 1 ? label?.page1 : label?.page2 : page === 1 ? label?.page1Eng : label?.page2Eng;

        const languageObjectKey = selectedLanguage === 'marathi' ? field : `${field}Eng`;

        return languageObject?.[languageObjectKey] || '';
    };


    const handleRented = () => {
        setrentedPersonMobile("")
        setrentedPersonName("")
        setrentedPersonNameError("")
        setrentedPersonMobileError("")
    }

    const handleIsSathaShopActLicenseRadioChange = (e) => {
        setisSathaShopActLicense(e.target.value === 'true');
    };

    const handleIsBusinessParvanaRadioChange = (e) => {
        setisBusinessParvana(e.target.value === 'true');
    };

    const handleIsBusinessShopActLicensRadioChange = (e) => {
        setisBusinessShopActLicense(e.target.value === 'true');
    };

    const getAwsCredentials = async () => {
        try {
            const payload = {
                "projectName": "PCMC_SURVEY"
            }
            await axios.post(process.env.REACT_APP_BASE_URL + '/credentials/getCredentials', payload)
                .then((response) => {
                    setAwsCredential(response.data.data)
                })
                .catch((error) => {
                    console.log("Error====>", error)
                })
        } catch (error) {
            console.log("Error====>", error)
        }
    }

    const s3Data = {
        projectName: awsCredential?.projectName,
        bucketName: awsCredential?.bucketName,
        region: awsCredential?.region,
        accessKeyId: 'AKIA4GWLDQ2LDVN27AYR',
        secretAccessKey: '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',
        s3Url: "https://foxberry-images.s3.amazonaws.com",
    };

    const handleProfileImg = async (e) => {
        let result = await ReactS3(e.target.files[0], s3Data, "citizen");
        setoutsideImage(result);
    };

    const finalSubmit = async (e) => {
        e.preventDefault();
        if (!buildingFloors) {
            setbuildingFloorsError("Please Enter Building Floor");
        } else if (!propertyFloor) {
            setbuildingFloorsError("");
            setpropertyFloorError("Please Enter Property Floor");
        } else if (!propertyAreaFoot) {
            setbuildingFloorsError("");
            setpropertyFloorError("");
            setpropertyAreaFootError("Please Enter Property Area Foot");
        } else if (!propertyAreaMeter) {
            setbuildingFloorsError("");
            setpropertyFloorError("");
            setpropertyAreaFootError("");
            setpropertyAreaMeterError("Please Enter Property Area Meter");
        } else if (!propertyWorkPlace) {
            setbuildingFloorsError("")
            setpropertyFloorError("")
            setpropertyAreaFootError("")
            setpropertyAreaMeterError("")
            setpropertyWorkPlaceError("Please Enter Property Work Place")
        } else if (isRented && !rentedPersonName) {
            setbuildingFloorsError("")
            setpropertyFloorError("")
            setpropertyAreaFootError("")
            setpropertyAreaMeterError("")
            setpropertyWorkPlaceError("")
            setrentedPersonNameError("Please Enter Rented Person Name")
        } else if (isRented && !rentedPersonMobile) {
            setbuildingFloorsError("")
            setpropertyFloorError("")
            setpropertyAreaFootError("")
            setpropertyAreaMeterError("")
            setpropertyWorkPlaceError("")
            setrentedPersonNameError("")
            setrentedPersonMobileError("Please Enter Rented Person Mobile")
        } else if (!outsideImage || outsideImage.length === 0) {
            setoutsideImageError("Please Upload Image");
        } else {
            setbuildingFloorsError("")
            setpropertyFloorError("")
            setpropertyAreaFootError("")
            setpropertyAreaMeterError("")
            setpropertyWorkPlaceError("")
            setrentedPersonNameError("")
            setrentedPersonMobileError("")
            setoutsideImageError("")


            const payload = {
                "surveyId": queryParams.get('surveyId'),
                "buildingFloors": buildingFloors,
                "propertyFloor": propertyFloor,
                "propertyAreaFoot": propertyAreaFoot,
                "propertyAreaMeter": propertyAreaMeter,
                "propertyWorkPlace": propertyWorkPlace,
                "subPropertyTypeId": subPropertyTypeId,
                "businessName": businessName,
                "ownerName": ownerName,
                "finalBusinessAddress": finalBusinessAddress,
                "addressRoadColony": addressRoadColony,
                "addressLandmark": addressLandmark,
                "ownerMobile": ownerMobile,
                "ownerEmail": ownerEmail,
                "isRented": isRented,
                "rentedPersonName": rentedPersonName,
                "rentedPersonMobile": rentedPersonMobile,
                "businessStartDate": businessStartDate,
                "isSatha": isSatha,
                "isVyavsay": isVyavsay,
                "isSathaShopActLicense": isSathaShopActLicense,
                "isBusinessParvana": isBusinessParvana,
                "isBusinessShopActLicense": isBusinessShopActLicense,
                "outsideImage": outsideImage,
                "isFireNOC": isFireNOC,
                "isResidentialPurpose": isResidentialPurpose,
                "isFireExtinguisherEquipment": isFireExtinguisherEquipment,
                "isAttic": isAttic,
                "isBasement": isBasement
            }

            try {
                await axios
                    .post(process.env.REACT_APP_BASE_URL + "/ownerform/addOwnerForm", payload)
                    .then((res) => {

                        if (res?.data?.message === "Owner Form Already Exists") {
                            navigation(`CitizenLayout/success`, { state: { formSubmitStatus: true } });
                        } else {
                            navigation(`CitizenLayout/success`, { state: { formSubmitStatus: false } });
                        }

                    })
                    .catch((err) => {
                        console.log("Error", err);
                    });
            } catch (error) {
                console.log("Error", error)
            }

        }
    }


    return (
        <>
            <div className="SurveyForm my-4">
                <Container>
                    <Row className='justify-content-end'>
                        <Col xs={4} md={2}>
                            <ButtonGroup aria-label="Basic example">
                                <Button size="sm"
                                    onClick={() => { setSelectedLanguage("marathi") }}
                                    active={selectedLanguage === "marathi"}
                                    style={{ backgroundColor: selectedLanguage === "marathi" ? '#004686' : 'white', color: selectedLanguage === "marathi" ? 'white' : '#004686' }}
                                >मराठी</Button>
                                <Button size="sm"
                                    onClick={() => { setSelectedLanguage('english') }}
                                    active={selectedLanguage === "english"}
                                    style={{ backgroundColor: selectedLanguage === "english" ? '#004686' : 'white', color: selectedLanguage === "english" ? 'white' : '#004686' }}
                                >English</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    <div className='Header d-flex flex-column flex-md-row justify-content-center align-items-center my-4'>
                        <img src={Logo} alt="PCMC" style={{ width: '70px' }} className='mb-2 mb-md-0' />
                        {
                            selectedLanguage === "marathi" ?
                                <span className='ms-3 text-center'>
                                    <h3 className='mb-2'>पिंपरी चिंचवड महानगरपालिका</h3>
                                    <h5>अग्निशमन विभागाचे सर्वेक्षण</h5>
                                </span>
                                :
                                <span className='ms-3 text-center'>
                                    <h3 className='mb-1'>Pimpri Chinchwad Muncipal Corporation</h3>
                                    <h5>Fire safety department Survey</h5>
                                </span>
                        }
                    </div>

                    {
                        loader ? <center><Spinner variant="primary" className='mt-3' /></center> :
                            <>
                                <Form data-aos="fade-up">
                                    <Row>
                                        <h6 className='headLabel'> {selectedLanguage === "marathi" ? "सामान्य माहिती " : "General Information"}</h6>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{getLabelName('buildingFloor', 1)}</Form.Label>
                                                <Form.Control value={buildingFloors} placeholder={getLabelName('buildingFloor', 1)}
                                                    onChange={(e) => { setbuildingFloors(e.target.value); }} type="number" />
                                            </Form.Group>
                                            {buildingFloorsError && <p className='errMsg'>{buildingFloorsError}</p>}
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group >
                                                <Form.Label>{getLabelName('propertyFloor', 1)}</Form.Label>
                                                <Form.Control value={propertyFloor} type="number" placeholder={getLabelName('propertyFloor', 1)}
                                                    onChange={(e) => { setpropertyFloor(e.target.value); }} />
                                            </Form.Group>
                                            {propertyFloorError && <p className='errMsg'>{propertyFloorError}</p>}
                                        </Col>

                                        <Col md={4}>
                                            <Row>
                                                <Form.Label>{getLabelName('propertyAreaHeader', 1)}</Form.Label>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <Form.Control value={propertyAreaFoot} type="number" placeholder={getLabelName('propertyAreaFoot', 1)} />
                                                        <InputGroup.Text>{selectedLanguage === "marathi" ? "चौ.फूट" : "Sq.foot"}</InputGroup.Text>
                                                        {/* <InputGroup.Text>{getLabelName('propertyAreaFoot', 1)}</InputGroup.Text> */}
                                                    </InputGroup>
                                                    {propertyAreaFootError && <p className="errMsg">{propertyAreaFootError}</p>}
                                                </Col>

                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <Form.Control value={propertyAreaMeter} type="number" placeholder={getLabelName('propertyAreaMeter', 1)}
                                                            onChange={(e) => { setpropertyAreaMeter(e.target.value) }} />
                                                        <InputGroup.Text>{selectedLanguage === "marathi" ? "चौ. मीटर" : "Sq. feet"}</InputGroup.Text>
                                                    </InputGroup>
                                                    {propertyAreaMeterError && <p className="errMsg">{propertyAreaMeterError}</p>}

                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xs={11} md={4}>
                                            <Form.Label>{getLabelName('propertyWorkPlace', 1)}</Form.Label>
                                            <Form.Group key={`radio`} className="mb-4 d-flex flex-column flex-md-row"
                                                onChange={(e) => { setpropertyWorkPlace(e.target.id); }}>

                                                <Form.Check label={getLabelName('MIDC', 1)} className='me-4'
                                                    name="group1" type={"radio"} id={label?.page1?.MIDC}
                                                    value={propertyWorkPlace}
                                                    checked={propertyWorkPlace === label?.page1?.MIDC} />
                                                <Form.Check className='me-4' label={getLabelName('PCMC', 1)} name="group1"
                                                    type={"radio"} id={label?.page1?.PCMC}
                                                    value={propertyWorkPlace} checked={propertyWorkPlace === label?.page1?.PCMC} />
                                                <Form.Check className='me-3'
                                                    label={getLabelName('PMRDA', 1)}
                                                    name="group1" type={"radio"}
                                                    id={label?.page1?.PMRDA} value={propertyWorkPlace}
                                                    defaultChecked={propertyWorkPlace === label?.page1?.PMRDA} />
                                                {propertyWorkPlaceError && <p className='errMsg'>{propertyWorkPlaceError}</p>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group onChange={(e) => {
                                                setsubPropertyType(JSON.parse(e.target.value).subPropertyType);
                                                setsubPropertyTypeId(
                                                    JSON.parse(e.target.value).subPropertyTypeId
                                                );
                                            }}
                                            >
                                                <Form.Label className="Label-muted ">
                                                    {getLabelName('businessType', 1)}
                                                    {/* {propertyTypeLabel?.propertyTypeLabel} */}
                                                </Form.Label>
                                                <Form.Select aria-label="Default select example">
                                                    {subpropertyType.length > 0 &&
                                                        subpropertyType.map((item, index) => {
                                                            return (
                                                                <option key={index} selected={subPropertyTypeId === item.subPropertyTypeId} value={JSON.stringify(item)}>
                                                                    {item.subPropertyType}
                                                                </option>
                                                            );
                                                        })}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{getLabelName('businessName', 1)}</Form.Label>
                                                <Form.Control value={businessName} onChange={(e) => { setBusinessName(e.target.value); }}
                                                    type="text" placeholder={getLabelName('businessName', 1)} />
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group >
                                                <Form.Label>{getLabelName('ownerName', 1)} </Form.Label>
                                                <Form.Control
                                                    value={ownerName}
                                                    onChange={(e) => {
                                                        setownerName(e.target.value);
                                                    }}
                                                    type="name"
                                                    placeholder={getLabelName('ownerName', 1)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>{getLabelName('ownerMobile', 1)}</Form.Label>
                                                <Form.Control
                                                    value={ownerMobile}
                                                    onChange={(e) => {
                                                        setownerMobile(e.target.value);
                                                    }}
                                                    type="mobile"
                                                    placeholder={getLabelName('ownerMobile', 1)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>{getLabelName('ownerEmail', 1)}</Form.Label>
                                                <Form.Control
                                                    value={ownerEmail}
                                                    onChange={(e) => {
                                                        setownerEmail(e.target.value);
                                                    }}
                                                    type="email"
                                                    placeholder={getLabelName('ownerEmail', 1)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>{getLabelName('finalBusinessAddress', 1)}</Form.Label>
                                                <Form.Control
                                                    value={finalBusinessAddress}
                                                    onChange={(e) => {
                                                        setfinalBusinessAddress(e.target.value);
                                                    }}
                                                    type="name"
                                                    placeholder={getLabelName('finalBusinessAddress', 1)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className='mb-4'>
                                            <Form.Group>
                                                <Form.Label>{getLabelName('addressRoadColony', 1)}</Form.Label>
                                                <Form.Control
                                                    onChange={(e) => {
                                                        setaddressRoadColony(e.target.value);
                                                    }}
                                                    value={addressRoadColony} type="name"
                                                    placeholder={getLabelName('addressRoadColony', 1)}
                                                />
                                            </Form.Group>

                                        </Col>

                                        <Col md={8}>
                                            <Form.Group >
                                                <Form.Label>{getLabelName('addressLandmark', 1)}</Form.Label>
                                                <Form.Control
                                                    onChange={(e) => { setaddressLandmark(e.target.value); }}
                                                    type="name" value={addressLandmark}
                                                    placeholder={getLabelName('addressLandmark', 1)} />
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group
                                                className="mb-4"
                                                controlId="formBasicEmail"
                                                onChange={(e) => {
                                                    setbusinessStartDate(e.target.value);
                                                }}
                                            >
                                                <Form.Label>{getLabelName('businessDate', 1)}</Form.Label>
                                                <Form.Control
                                                    value={businessStartDate}
                                                    type="date"
                                                    placeholder={getLabelName('businessDate', 1)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Label >
                                                {getLabelName('isRented', 1)}
                                            </Form.Label>

                                            <Form.Group className="mb-4">
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={getLabelName('rentedOwnerName', 1)}
                                                    name="radioGroup"
                                                    value="true"
                                                    checked={isRented === true}
                                                    onChange={() => setisRented(true)}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={getLabelName('originalOwnerName', 1)}
                                                    name="radioGroup"
                                                    value="false"
                                                    checked={isRented === false}
                                                    onChange={() => setisRented(false)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        {
                                            isRented ? <>
                                                <Col md={4}>
                                                    <Form.Group >
                                                        <Form.Label>{getLabelName('rentedPersonName', 1)}</Form.Label>
                                                        <Form.Control
                                                            value={rentedPersonName}
                                                            onChange={(e) => {
                                                                setrentedPersonName(e.target.value);
                                                            }}
                                                            type="name"
                                                            placeholder={getLabelName('rentedPersonName', 1)}
                                                        />
                                                        {rentedPersonNameError && <p className='errMsg'>{rentedPersonNameError}</p>}
                                                    </Form.Group>
                                                </Col>

                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{getLabelName('rentedPersonMobile', 1)}</Form.Label>
                                                        <Form.Control
                                                            value={rentedPersonMobile}
                                                            onChange={(e) => {
                                                                setrentedPersonMobile(e.target.value);
                                                            }}
                                                            type="number"
                                                            placeholder={getLabelName('rentedPersonMobile', 1)}
                                                        />
                                                        {rentedPersonMobileError && <p className='errMsg'>{rentedPersonMobileError}</p>}
                                                    </Form.Group>
                                                </Col>

                                            </> : ''
                                        }

                                        { /*  ------------------------ Information Related to Skysign Department  ----------------------*/}
                                        <h6 className='headLabel'> {selectedLanguage === "marathi" ? "परवाना संबंधित माहिती" : "Information Related to Skysign Department"}</h6>

                                        <Row>
                                            <Col md={6}>
                                                <Form.Label >
                                                    {getLabelName('businessCategory', 1)}
                                                </Form.Label>
                                            </Col>

                                            <Form.Group
                                                key={`isSathaAndIsVyavsay`}
                                                className="mb-4"
                                                onChange={(e) => {
                                                    if (e.target.id == "साठा" && e.target.checked) {
                                                        setisSatha(true);
                                                    } else if (e.target.id == "साठा" && !e.target.checked) {
                                                        setisSatha(false);
                                                    }

                                                    if (e.target.id == "व्यवसाय" && e.target.checked) {
                                                        setisVyavsay(true);
                                                    } else if (e.target.id == "व्यवसाय" && !e.target.checked) {
                                                        setisVyavsay(false);
                                                    }
                                                }}
                                            >

                                                <Form.Check
                                                    inline
                                                    label={selectedLanguage === 'marathi' ? "साठा" : "Go-down"}
                                                    name="group1"
                                                    type={"checkbox"}
                                                    id={"साठा"}
                                                    defaultChecked={isSatha}
                                                />
                                                <Form.Check
                                                    inline
                                                    label={selectedLanguage === 'marathi' ? "व्यवसाय" : "Bussiness"}
                                                    name="group2"
                                                    type={"checkbox"}
                                                    id={"व्यवसाय"}
                                                    defaultChecked={isVyavsay}
                                                />
                                            </Form.Group>

                                            <div style={{ display: isSatha ? "block" : "none" }}>
                                                <Form.Label >
                                                    {getLabelName('isSathaShopAct', 1)}
                                                </Form.Label>
                                                <Form.Group
                                                    key={`isSathaShopActLicense`}
                                                    className="mb-4"
                                                    onChange={(e) => {
                                                        setisSathaShopActLicense(e.target.id == "true" ? true : false);
                                                    }}
                                                >

                                                    <Form.Check
                                                        defaultChecked={isSathaShopActLicense === true}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                                                        name="group3"
                                                        type={"radio"}
                                                        id={"true"}
                                                        onChange={handleIsSathaShopActLicenseRadioChange}

                                                    />
                                                    <Form.Check
                                                        defaultChecked={isSathaShopActLicense === false}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                                                        name="group3"
                                                        type={"radio"}
                                                        id={"false"}
                                                        onChange={handleIsSathaShopActLicenseRadioChange}
                                                    />
                                                </Form.Group>


                                            </div>

                                            <div style={{ display: isVyavsay ? "block" : "none" }}>
                                                <Form.Group className="mb-4 " controlId="formBasicEmail">
                                                    <Form.Label>{getLabelName('businessParvana', 1)}</Form.Label>
                                                </Form.Group>

                                                <Form.Label >
                                                    {getLabelName('isBusinessParvana', 1)}
                                                </Form.Label>

                                                <Form.Group
                                                    key={`radio3`}
                                                    className="mb-4"
                                                    onChange={(e) => {
                                                        setisBusinessParvana(e.target.id == "true" ? true : false);
                                                    }}
                                                >

                                                    <Form.Check
                                                        defaultChecked={isBusinessParvana === true}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                                                        name="group4"
                                                        type={"radio"}
                                                        id={"true"}
                                                        onChange={handleIsBusinessParvanaRadioChange}
                                                    />
                                                    <Form.Check
                                                        defaultChecked={isBusinessParvana === false}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                                                        name="group4"
                                                        type={"radio"}
                                                        id={"false"}
                                                        onChange={handleIsBusinessParvanaRadioChange}
                                                    />
                                                </Form.Group>

                                                <Form.Label >
                                                    {getLabelName('isBusinessShopAct', 1)}
                                                </Form.Label>
                                                <Form.Group
                                                    key={`radio4`}
                                                    className="mb-4"
                                                    onChange={(e) => {
                                                        setisBusinessShopActLicense(
                                                            e.target.id == "true" ? true : false
                                                        );
                                                    }}
                                                >

                                                    <Form.Check
                                                        defaultChecked={isBusinessShopActLicense === true}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                                                        name="group5"
                                                        type={"radio"}
                                                        id={"true"}
                                                        onChange={handleIsBusinessShopActLicensRadioChange}
                                                    />
                                                    <Form.Check
                                                        defaultChecked={isBusinessShopActLicense === false}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                                                        name="group5"
                                                        type={"radio"}
                                                        id={"false"}
                                                        onChange={handleIsBusinessShopActLicensRadioChange}
                                                    />
                                                </Form.Group>

                                            </div>

                                        </Row>


                                        <h6 className='headLabel'> {selectedLanguage === "marathi" ? "अग्निशमन संबंधित माहिती" : "Information Related to Fire Department"}</h6>

                                        <Row>
                                            <Col md={4}>
                                                <Form.Group>

                                                    {outsideImage ?
                                                        <>
                                                            <Form.Label>{selectedLanguage === 'marathi' ? "व्यायसायाचा फोटो अपलोड करा" : "Uploaded Property Image"}</Form.Label>
                                                            <div className='uploadFile'>
                                                                <img src={outsideImage} alt="profile Image" />
                                                                <h6>{String(outsideImage)?.split('/')[String(outsideImage)?.split('/').length - 1]}</h6>
                                                                <Icon.XLg className='me-2' size={20} onClick={() => setoutsideImage('')} />
                                                            </div>
                                                        </> :
                                                        <>
                                                            <Form.Label>{getLabelName('outsideImage', 2)}</Form.Label>
                                                            <Form.Control type='file' onChange={(e) => {
                                                                handleProfileImg(e)
                                                            }} />
                                                        </>
                                                    }
                                                    {outsideImageError && <p style={{ color: 'red' }}>{outsideImageError}</p>}
                                                </Form.Group>
                                            </Col>

                                            <Col md={4}>
                                                <Form.Label>
                                                    {getLabelName('fireNOC', 2)}
                                                </Form.Label>

                                                <Form.Group
                                                    key={`radio5`}
                                                    className="mb-4"
                                                    onChange={(e) => {
                                                        setisFireNOC(e.target.id == "true" ? true : false);
                                                    }}
                                                >
                                                    <Form.Check
                                                        defaultChecked={isFireNOC}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                                                        name="group2"
                                                        type={"radio"}
                                                        id={"true"}
                                                    />
                                                    <Form.Check
                                                        defaultChecked={!isFireNOC}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                                                        name="group2"
                                                        type={"radio"}
                                                        id={"false"}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col md={4}>
                                                <Form.Label >
                                                    {getLabelName('isResidentialPurpose')}
                                                </Form.Label>
                                                <Form.Group
                                                    key={`radio6`}
                                                    className="mb-4"
                                                    onChange={(e) => {
                                                        setisResidentialPurpose(e.target.id == "true" ? true : false);
                                                    }}
                                                >

                                                    <Form.Check
                                                        inline
                                                        defaultChecked={isResidentialPurpose}
                                                        label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                                                        name="group5"
                                                        type={"radio"}
                                                        id={"true"}
                                                    />
                                                    <Form.Check
                                                        defaultChecked={!isResidentialPurpose}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                                                        name="group5"
                                                        type={"radio"}
                                                        id={"false"}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col md={4}>

                                                <Form.Label >
                                                    {getLabelName('fireExtinguisherEquipemnts')}
                                                </Form.Label>
                                                <Form.Group
                                                    key={`radio7`}
                                                    className="mb-4"
                                                    onChange={(e) => {
                                                        setisFireExtinguisherEquipment(
                                                            e.target.id == "true" ? true : false
                                                        );
                                                    }}
                                                >

                                                    <Form.Check
                                                        defaultChecked={isFireExtinguisherEquipment}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                                                        name="group6"
                                                        type={"radio"}
                                                        id={"true"}
                                                    />
                                                    <Form.Check
                                                        defaultChecked={!isFireExtinguisherEquipment}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                                                        name="group6"
                                                        type={"radio"}
                                                        id={"false"}
                                                    />
                                                </Form.Group>

                                            </Col>

                                            <Col md={4}>

                                                <Form.Label >
                                                    {getLabelName('isAttic')}
                                                </Form.Label>
                                                <Form.Group
                                                    key={`radio8`}
                                                    className="mb-4"
                                                    onChange={(e) => {
                                                        setisAttic(e.target.id == "true" ? true : false);
                                                    }}
                                                >
                                                    <Form.Check
                                                        defaultChecked={isAttic}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                                                        name="group8"
                                                        type={"radio"}
                                                        id={"true"}
                                                    />
                                                    <Form.Check
                                                        defaultChecked={!isAttic}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                                                        name="group8"
                                                        type={"radio"}
                                                        id={"false"}
                                                    />
                                                </Form.Group>

                                            </Col>

                                            <Col md={4}>
                                                <Form.Label >
                                                    {getLabelName('isBasement')}
                                                </Form.Label>
                                                <Form.Group
                                                    key={`radio9`}
                                                    className="mb-4"
                                                    onChange={(e) => {
                                                        setisBasement(e.target.id == "true" ? true : false);
                                                    }}
                                                >
                                                    <Form.Check
                                                        defaultChecked={isBasement}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                                                        name="group9"
                                                        type={"radio"}
                                                        id={"true"}
                                                    />
                                                    <Form.Check
                                                        defaultChecked={!isBasement}
                                                        inline
                                                        label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                                                        name="group9"
                                                        type={"radio"}
                                                        id={"false"}
                                                    />
                                                </Form.Group>
                                            </Col>








                                            <div className="d-flex justify-content-center">
                                                <Button
                                                    className="primaryBtn"
                                                    onClick={(e) => {
                                                        finalSubmit(e);
                                                    }}
                                                >
                                                    <Icon.Send className='me-2' />
                                                    {selectedLanguage === "marathi" ? "माहिती जतन करा" : "Submit"}
                                                </Button>
                                            </div>


                                        </Row>
                                    </Row>
                                </Form>

                            </>
                    }
                </Container>
            </div >


        </>
    )
}

export default SurveyForm