import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '../Common/Pagination';
import { useNavigate } from 'react-router-dom';
import Skeleton from '../Common/Skeleton';

const ManageContractors = () => {

    const navigate = useNavigate();
    const [data, setData] = useState('');
    const [loader, setLoader] = useState(false);
    const [paginate, setPaginate] = useState(true);
    
    const [searchMobile, setSearchMobile] = useState('');
    const [searchMobileErr, setSearchMobileErr] = useState(false);

//   const [date, setDate] = useState('');

    // States for Pagination ----------
        const [pageNo, setPageNo] = useState(1);
        const [docPerPage, setDocPerPage] = useState(50);
        const [noOfPages, setNoOfPages] = useState();


    // function for pagination ----------
           
      const handlePageChange = (pageNumber) => {
           setPageNo(pageNumber);
       };
       
       const handleDocsPerPage = (docsPageProp) => {
           setPageNo(1)
           setDocPerPage(docsPageProp);
       }


    useEffect(()=>{
        getContractors();
    },[pageNo, docPerPage])

    const getContractors = async ()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/surveys/contractorWiseSurveyReport', {
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                surveyDate: "",
                mobile: searchMobile,
                docsPerPage: docPerPage,
                pageNo: pageNo
            })
        }).then(res=>res.json()).then((response)=>{
            if(searchMobile.length===10){
                setPaginate(false);
            }
            setData(response?.data);
            setNoOfPages(response?.data?.noOfPages);
            setLoader(false);
        }).catch((Err)=>{
            console.log("Err while getting contractors", Err);
            setLoader(false);
        })
    }


    // --------------- Search User By Mobile ------------------------------
        const searchByValidate =()=>{
            let isValid = true;
            if(!searchMobile || searchMobile.length<10){
                isValid= false ;
                setSearchMobileErr(true);
            }
            return isValid;
        }
    
        const searchByMobile = async()=>{
            if(searchByValidate()){
            getContractors();
            }
        }

  return (
    <div className='ManageContractors outletPadding'>
        <Container>
        {/* <Row>
            <p className='refresh ms-auto' onClick={()=>{ window.location.reload(false)}}>
                <Icon.ArrowClockwise className="ms-3 me-1" size={20} />Refresh
            </p>
          </Row> */}

        <Row className='justify-content-end'>
              <Col md={3}>
                {/* <Form.Label>Search by mobile</Form.Label> */}
                  <div className='d-flex'>
                  <Form.Control placeholder='Enter Mobile No.' value={searchMobile} className='me-3'
                    onChange={(e)=>{ 
                      if(e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10){
                        setSearchMobile(e.target.value)
                      }
                      else if (e.target.value.length === 0){
                        setSearchMobile(e.target.value)
                      }
                  e.target.value.length>0 ? setSearchMobileErr(false): setSearchMobileErr(true); }} />
                  <Button className='primaryBtn' onClick={searchByMobile}>
                      <Icon.Search className='me-2' size={15}/>Search
                  </Button>
                  </div>
              { searchMobileErr ? <p className='errMsg'>Please enter valid mobile no.</p> : '' }
            </Col>

            {/* <Col md={3}>
              <Form.Group>
                <Form.Label>Filter By Date</Form.Label> 
                <div className='d-flex align-items-center'>
                <Form.Control type='date' maz={new Date()} value={date} onChange={(e)=>setDate(moment(e.target.value).format('YYYY-MM-DD'))}/>
                <p className='refresh' onClick={()=>setDate('')}>
                  <Icon.ArrowClockwise className="ms-3 me-1" size={20} />Refresh
                </p>
                </div>
              </Form.Group>
            </Col> */}
        </Row>


            <div className="outer-wrapper mx-auto mt-4">
                {
                   <>
                    <div className="table-wrapper" style={{maxHeight:'70vh'}} 
                        data-aos="fade-up" data-aos-delay="100">
                        <table data-aos="fade-up" data-aos-delay="200">
                            <thead>
                                <th>Sr. No.</th>
                                <th>Name</th>
                                <th>Mobile Number</th>
                                <th>Email ID</th>
                                <th>Zone</th>
                                <th>Gat</th>
                                <th>Only<br/> Searched</th>
                                <th>Partly<br/>Submitted</th>
                                <th>Fully<br/> Submitted</th>
                                <th>Actions</th>
                            </thead>
                {     loader ? <Skeleton rows={10} cols={10}/> :
                    data?.users!== null && data.users !== undefined && data?.users.length > 0 ? data?.users.map((itm, index) => {

                        const { name, mobile, zoneno, gatname, email, onlySearchCount, partlySubmitCount, fullySubmitCount} = itm

                                        return (
                                            <tr key={Math.random() * 999999999}>
                                                <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                                <td style={{width:'18vw'}}>{name}</td>
                                                <td>{mobile}</td>
                                                <td>{email?email:'-'}</td>
                                                <td>{zoneno}</td>
                                                <td>{gatname.toString()}</td>
                                                <td>{onlySearchCount}</td>
                                                <td>{partlySubmitCount}</td>
                                                <td>{fullySubmitCount}</td>
                                                <td>
                                                <Icon.Eye size={22} className='ms-2 me-4 icon' 
                                                 onClick={()=>navigate('/dashboard/view-single-survey', {state:itm} )}/> 
                                                </td>
                                            </tr>
                                        )
                                    })
                                 : <p className='noDataFound'>No Data Found</p>
                }   
                  </table>
              </div>
        <Row>
            <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>
                <Icon.People className='me-2'/>Total - <strong>{data?.count}</strong>
            </h6>
            </Col>
            <Col md={8} className='d-flex justify-content-end'>
            { paginate && <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange} 
        onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage}/> }
            </Col>
        </Row>
      
        </>
                }
            </div>
        </Container>
    </div>
  )
}

export default ManageContractors