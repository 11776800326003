import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import ReactS3 from "../../Utils/ReactS3";
import * as Icon from 'react-bootstrap-icons';
import { useLocation, useNavigate } from "react-router-dom";

window.Buffer = window.Buffer || require("buffer").Buffer;
export default function Formthree() {
  const navigation = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ownerFormId = location.state

  const [selectedLanguage, setSelectedLanguage] = useState('english');


  const [label, setlabel] = useState("");
  const [dropdown, setdropdown] = useState("");
  const [loader, setloader] = useState(false);
  const [isFireNOC, setisFireNOC] = useState("");
  const [fireNOCNumber, setfireNOCNumber] = useState("");
  const [fireNOCDate, setfireNOCDate] = useState("");
  const [fireNOCImage, setfireNOCImage] = useState([]);
  const [fireAnnualNumber, setfireAnnualNumber] = useState("");
  const [isFireAnnualRenewal, setisFireAnnualRenewal] = useState("");
  const [fireAnnualDate, setfireAnnualDate] = useState("");
  const [fireAnnualImage, setfireAnnualImage] = useState([]);
  const [isFireSafetyCertificate, setisFireSafetyCertificate] = useState("");
  const [fireSafetyCertificateDate, setfireSafetyCertificateDate] =
    useState("");
  const [fireSafetyCertificateImage, setfireSafetyCertificateImage] = useState(
    []
  );
  const [isFireExtinguisherEquipment, setisFireExtinguisherEquipment] =
    useState("");
  const [fireSafetyCertificateNumber, setfireSafetyCertificateNumber] =
    useState("");
  const [fireExtinguisherEquipments, setfireExtinguisherEquipments] =
    useState("");
  const [fireExtinguisherEquipmentsOther, setfireExtinguisherEquipmentsOther] =
    useState("");
  const [fireExtinguisherEquipmentImage, setfireExtinguisherEquipmentImage] =
    useState([]);
  const [electricMeter, setelectricMeter] = useState("");
  const [electricMeterNumber, setelectricMeterNumber] = useState("");
  const [electricMeterImage, setelectricMeterImage] = useState("");
  const [isHazardous, setisHazardous] = useState("");
  const [hazardousEquipment, sethazardousEquipment] = useState("");
  const [hazardousEquipmentOther, sethazardousEquipmentOther] = useState("");
  const [hazardousEquipmentImage, sethazardousEquipmentImage] = useState([]);
  const [isAllowedInside, setisAllowedInside] = useState("");
  const [outsideImage, setoutsideImage] = useState("");
  const [outsideImageError, setoutsideImageError] = useState("");
  const [insideImage, setinsideImage] = useState([]);
  const [isAttic, setisAttic] = useState("");
  const [atticInfo, setatticInfo] = useState("");
  const [atticInfoOther, setatticInfoOther] = useState("");
  const [atticImage, setatticImage] = useState([]);
  const [isBasement, setisBasement] = useState("");
  const [basementInfo, setbasementInfo] = useState("");
  const [basementInfoOther, setbasementInfoOther] = useState("");
  const [basementImage, setbasementImage] = useState("");
  const [isResidentialPurpose, setisResidentialPurpose] = useState("");
  const [residentialPurposes, setresidentialPurposes] = useState([]);
  const [residentialPurposesOther, setresidentialPurposesOther] = useState("");
  const [residentialImage, setresidentialImage] = useState([]);
  const [lat, setlat] = useState("");
  const [long, setlong] = useState("");
  const [surveyById, setsurveyById] = useState("");
  const [surveyByName, setsurveyByName] = useState("");
  const [surveyByMobile, setsurveyByMobile] = useState("");
  const [version, setversion] = useState("");
  const getLebal = async () => {
    setloader(true);
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/labels/getLabels")
      .then((res) => {
        if (res.data.data) {
          setlabel(res.data.data);
          //getdropdownvalue()
          getIntialValue();
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const getIntialValue = async () => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/surveys/getSurveyBySurveyId", {
        surveyId: "SURVEY_1110100008_042023032024_9359330702",
      })
      .then((res) => {
        setloader(false);
        setdropdown(res.data.dropdownValues);
        if (res.data.page3) {
          const e = res.data.page3;

          setisFireNOC(e.isFireNOC);
          setfireNOCNumber(e.fireNOCNumber);
          setfireNOCDate(e.fireNOCDate);
          setfireNOCImage(e.fireNOCImage);
          setfireAnnualNumber(e.fireAnnualNumber);
          setisFireAnnualRenewal(e.isFireAnnualRenewal);
          setfireAnnualDate(e.fireAnnualDate);
          setfireAnnualImage(e.fireAnnualImage);
          setisFireSafetyCertificate(e.isFireSafetyCertificate);
          setfireSafetyCertificateDate(e.fireSafetyCertificateDate);
          setfireSafetyCertificateImage(e.fireSafetyCertificateImage);
          setisFireExtinguisherEquipment(e.isFireExtinguisherEquipment);
          setfireSafetyCertificateNumber(e.fireSafetyCertificateNumber);
          setfireExtinguisherEquipments(
            e.fireExtinguisherEquipments ? e.fireExtinguisherEquipments : []
          );
          setfireExtinguisherEquipmentsOther(e.fireExtinguisherEquipmentsOther);
          setfireExtinguisherEquipmentImage(e.fireExtinguisherEquipmentImage);
          setelectricMeterNumber(e.electricMeterNumber);
          setelectricMeterImage(e.electricMeterImage);
          setisHazardous(e.isHazardous);
          sethazardousEquipment(
            e.hazardousEquipment ? e.hazardousEquipment : []
          );
          sethazardousEquipmentOther(e.hazardousEquipmentOther);
          sethazardousEquipmentImage(e.hazardousEquipmentImage);
          setisAllowedInside(e.isAllowedInside);
          setoutsideImage(e.outsideImage);
          setisAttic(e.isAttic);
          setatticInfo(e.atticInfo ? e.atticInfo : []);
          setatticInfoOther(e.atticInfoOther);
          setatticImage(e.atticImage);
          setisBasement(e.isBasement);
          setbasementInfo(e.basementInfo ? e.basementInfo : []);
          setbasementImage(e.basementImage);
          setisResidentialPurpose(e.isResidentialPurpose);
          setresidentialPurposes(
            e.residentialPurposes ? e.residentialPurposes : []
          );
          setresidentialPurposesOther(e.residentialPurposesOther);
          setresidentialImage(e.residentialImage);
          setlat(e.lat);
          setlong(e.long);
          setsurveyById(e.surveyById);
          setsurveyByName(e.surveyByName);
          setsurveyByMobile(e.surveyByMobile);
          setversion(e.version);
        }
      })
      .catch((err) => {
        setloader(false);
      });
  };
  const getdropdownvalue = async () => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/dropdownValues/getAllDropdownValues",
        {
          propertyCode: "1020100097.00",
          user_id: "",
        }
      )
      .then((res) => {
        if (res.data.dropdownValues) {
          setloader(false);
          setdropdown(res.data.dropdownValues);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const Updatepage3 = async (e) => {
    if (!outsideImage || outsideImage.length === 0) {
      setoutsideImageError("Please Upload Image");
    } else {
      setoutsideImageError("")

      const payload = {
        "ownerFormId": ownerFormId?.ownerFormId,
        "isFireNOC": isFireNOC,
        "fireNOCNumber": fireNOCNumber,
        "fireNOCDate": fireNOCDate,
        "fireNOCImage": fireNOCImage,
        "isFireAnnualRenewal": isFireAnnualRenewal,
        "fireAnnualNumber": fireAnnualNumber,
        "fireAnnualDate": fireAnnualDate,
        "fireAnnualImage": fireAnnualImage,
        "isFireSafetyCertificate": isFireSafetyCertificate,
        "fireSafetyCertificateDate": fireSafetyCertificateDate,
        "fireSafetyCertificateImage": fireSafetyCertificateImage,
        "isFireExtinguisherEquipment": isFireExtinguisherEquipment,
        "fireSafetyCertificateNumber": fireSafetyCertificateNumber,
        "fireExtinguisherEquipments": fireExtinguisherEquipments,
        "fireExtinguisherEquipmentsOther": fireExtinguisherEquipmentsOther,
        "fireExtinguisherEquipmentImage": fireExtinguisherEquipmentImage,
        "electricMeterNumber": electricMeterNumber,
        "electricMeterImage": electricMeterImage,
        "isHazardous": isHazardous,
        "hazardousEquipment": hazardousEquipment,
        "hazardousEquipmentOther": hazardousEquipmentOther,
        "hazardousEquipmentImage": hazardousEquipmentImage,
        "isAllowedInside": isAllowedInside,
        "outsideImage": outsideImage,
        "insideImage": insideImage,
        "isAttic": isAttic,
        "atticInfo": atticInfo,
        "atticInfoOther": atticInfoOther,
        "atticImage": atticImage,
        "isBasement": isBasement,
        "basementInfo": basementInfo,
        "basementInfoOther": basementInfoOther,
        "basementImage": basementImage,
        "isResidentialPurpose": isResidentialPurpose,
        "residentialPurposes": residentialPurposes,
        "residentialPurposesOther": residentialPurposesOther,
        "residentialImage": residentialImage
      }


      await axios
        .post(process.env.REACT_APP_BASE_URL + "/ownerform/updateOwnerFormPage3", payload)
        .then((res) => {
          console.log("res", res)
          navigation('/CitizenLayout/success');

        })
        .catch((err) => {
          console.log(err);
        });
    }

  };

  const [awsCredential, setAwsCredential] = useState("")

  const getAwsCredentials = async () => {
    try {
      const payload = {
        "projectName": "PCMC_SURVEY"
      }
      await axios.post(process.env.REACT_APP_BASE_URL + '/credentials/getCredentials', payload)
        .then((response) => {
          setAwsCredential(response.data.data)
        })
        .catch((error) => {
          console.log("Error====>", error)
        })
    } catch (error) {
      console.log("Error====>", error)
    }
  }

  useEffect(() => {
    getLebal();
    getAwsCredentials()
  }, []);


  const s3Data = {
    projectName: awsCredential?.projectName,
    bucketName: awsCredential?.bucketName,
    region: awsCredential?.region,
    accessKeyId: 'AKIA4GWLDQ2LDVN27AYR',
    secretAccessKey: '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',
    s3Url: "https://foxberry-images.s3.amazonaws.com",
  };



  const handleProfileImg = async (e) => {
    let result = await ReactS3(e.target.files[0], s3Data, "citizen");
    setoutsideImage(result);
  };

  const getLabelName = (field) => {
    const languageObject = selectedLanguage === 'marathi' ? label?.page2 : label?.page2Eng;

    const languageObjectKey = selectedLanguage === 'marathi' ? field : `${field}Eng`;

    return languageObject?.[languageObjectKey] || '';
  };

  return (
    <>
      <Row>
        <h1 className="text-center">Form 3</h1>
      </Row>
      <div style={{ padding: 20, display: "flex", justifyContent: "center" }}>
        {loader ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Form className="form-layout">

            <Form.Group className='mb-3'>
              {outsideImage ?
                <>
                  <Form.Label>{selectedLanguage === 'marathi' ? "अपलोड केलेली प्रतिमा" : "Uploaded Image"}</Form.Label>
                  <div className='uploadFile'>
                    <img src={outsideImage} alt="profile Image" />
                    <h6>{String(outsideImage)?.split('/')[String(outsideImage)?.split('/').length - 1]}</h6>
                    <Icon.XLg className='me-2' size={20} onClick={() => setoutsideImage('')} />
                  </div>
                </> :
                <>
                  <Form.Label>{getLabelName('outsideImage')}</Form.Label>
                  <Form.Control type='file' onChange={(e) => {
                    handleProfileImg(e)
                  }} />
                </>
              }
              {outsideImageError && <p style={{ color: 'red' }}>{outsideImageError}</p>}
            </Form.Group>





            {/* <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Text>{label?.page2?.outsideImage}</Form.Text>
            </Form.Group> */}



            <Form.Label className="Label-muted">
              {getLabelName('fireNOC')}
            </Form.Label>
            <Form.Group
              key={`radio`}
              className="mb-4"
              onChange={(e) => {
                setisFireNOC(e.target.id == "true" ? true : false);
              }}
            >
              <Form.Check
                checked={isFireNOC}
                inline
                label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                name="group2"
                type={"radio"}
                id={"true"}
              />
              <Form.Check
                checked={!isFireNOC}
                inline
                label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                name="group2"
                type={"radio"}
                id={"false"}
              />
            </Form.Group>

            <Form.Label className="Label-muted">
              {getLabelName('isResidentialPurpose')}
            </Form.Label>
            <Form.Group
              key={`radio`}
              className="mb-4"
              onChange={(e) => {
                setisResidentialPurpose(e.target.id == "true" ? true : false);
              }}
            >
              <Form.Check
                inline
                checked={isResidentialPurpose}
                label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                name="group5"
                type={"radio"}
                id={"true"}
              />
              <Form.Check
                checked={!isResidentialPurpose}
                inline
                label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                name="group5"
                type={"radio"}
                id={"false"}
              />
            </Form.Group>

            <Form.Label className="Label-muted">
              {getLabelName('fireExtinguisherEquipemnts')}
            </Form.Label>
            <Form.Group
              key={`radio`}
              className="mb-4"
              onChange={(e) => {
                setisFireExtinguisherEquipment(
                  e.target.id == "true" ? true : false
                );
              }}
            >
              <Form.Check
                checked={isFireExtinguisherEquipment}
                inline
                label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                name="group6"
                type={"radio"}
                id={"true"}
              />
              <Form.Check
                checked={!isFireExtinguisherEquipment}
                inline
                label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                name="group6"
                type={"radio"}
                id={"false"}
              />
            </Form.Group>


            <Form.Label className="Label-muted">
              {getLabelName('isAttic')}
            </Form.Label>
            <Form.Group
              key={`radio`}
              className="mb-4"
              onChange={(e) => {
                setisAttic(e.target.id == "true" ? true : false);
              }}
            >
              <Form.Check
                checked={isAttic}
                inline
                label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                name="group8"
                type={"radio"}
                id={"true"}
              />
              <Form.Check
                checked={!isAttic}
                inline
                label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                name="group8"
                type={"radio"}
                id={"false"}
              />
            </Form.Group>

            <Form.Label className="Label-muted">
              {getLabelName('isBasement')}
            </Form.Label>
            <Form.Group
              key={`radio`}
              className="mb-4"
              onChange={(e) => {
                setisBasement(e.target.id == "true" ? true : false);
              }}
            >
              <Form.Check
                checked={isBasement}
                inline
                label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                name="group9"
                type={"radio"}
                id={"true"}
              />
              <Form.Check
                checked={!isBasement}
                inline
                label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                name="group9"
                type={"radio"}
                id={"false"}
              />
            </Form.Group>


            <div className="d-flex justify-content-end">
              <Button
                className="primaryBtn"
                onClick={(e) => {
                  Updatepage3(e);
                }}
              >
                माहिती जतन करा
              </Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}
