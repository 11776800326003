import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '../Common/Pagination';
import moment from 'moment';
import NearBy from '../../Assets/NearBy.svg';
import NearByLoader from '../../Assets/NearbyLoader.json';
import Lottie from 'react-lottie';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from 'xlsx';


const NearbyReport = () => {

    const [data, setData] = useState();

    const [loader, setLoader] = useState(false);

    const [lat, setLat] = useState('');
    const [latErr, setLatErr] = useState(false);

    const [long, setLong] = useState('');
    const [longErr, setLongErr] = useState(false);

    const [area, setArea] = useState('');
    const [areaErr, setAreaErr] = useState(false);

    // States for Pagination ----------
        const [pageNo, setPageNo] = useState(1);
        const [docPerPage, setDocPerPage] = useState(50);
        const [noOfPages, setNoOfPages] = useState(); 


    // function for pagination
           
      const handlePageChange = (pageNumber) => {
           setPageNo(pageNumber);
       };
       
       const handleDocsPerPage = (docsPageProp) => {
           setPageNo(1)
           setDocPerPage(docsPageProp);
       }


    useEffect(()=>{
      if(pageNo!==1 || docPerPage!==50){
        getNearbyReport();
      }
    },[pageNo, docPerPage])


   const getNearbyReport = async()=>{
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/surveys/dashboardNearBy', {
      method:'POST',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({ 
        lat: lat,
        long: long,
        area: area,
        documentsPerPage: docPerPage,
        page: pageNo
      })
    }).then((res)=>res.json()).then((response)=>{
        if(response?.message === "No Data Found"){
          toast.error('No Data Found');
          setLoader(false);
        }
        else if(response?.data.length>0){
          setData(response);
          setNoOfPages(response?.noOfPages);
          setLoader(false);   
       }
    }).catch((err)=>{
      console.log("Err while getting users", err);
      setLoader(false);
    })
  }

  const isValidate =()=>{
    let isValid = true;
      if(!lat){
          isValid= false ;
          setLatErr(true);
      }
      else if(!long){
        isValid = false;
        setLongErr(true);
      }
      else if(!area){
        isValid = false;
        setAreaErr(true);
      }
    return isValid;
}


  const searchByLatLongArea =()=>{
    if(isValidate()){
      getNearbyReport();
    }
  }
   
  const NearbyLoader={
    loop: true,
    autoplay: true,
    animationData: NearByLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }


  const downloadNearbyExcel = async()=>{

        const keys_to_keep = ['propertyCode', 'propertyName', 'zonename', 'zoneno', 'gatname', 'lat', 'long',
          'verifiedByName', 'verifiedByMobile', 'isVerified', 'notVerifiedReason', 'verifiedDate', 'surveyDate', 
          'ownerName', 'ownerMobile', 'businessAddress', 'finalBusinessAddress' ];

            const ExcelData = data?.data?.map(e => {
                const obj = {};
                keys_to_keep.forEach(k => obj[k] = e[k])
                return obj;
              });

              const downloadExcel = (heading, fileName) => {
                let dataSet = [];
                dataSet = heading;
                const worksheet = XLSX.utils.json_to_sheet(dataSet);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                XLSX.writeFile(workbook, fileName);
              };
    
              downloadExcel(ExcelData, `NearBy Report.xlsx`);
  }


  return (
    <div className='NearbyReport outletPadding'>
      <ToastContainer/>
        <Container>
        <Row className='justify-content-center'>
              <Col md={2} className='mb-3 mb-md-0'>
                <Form.Label>Latitude</Form.Label>
                    <Form.Control placeholder='Enter Latitude' value={lat} className='me-3'
                      onChange={(e)=>{ 
                        if (e.target.value.match(/^[0-9.]+$/)) {
                            setLat(e.target.value);
                        } else if (e.target.value.length === 0) {
                          setLat(e.target.value);
                        }
                        e.target.value.length > 0 ? setLatErr(false) : setLatErr(true);
                      }} />
                    { latErr ? <p className='errMsg'>Please enter valid latitude</p> : '' }
                  </Col>

                  <Col md={2} className='mb-3 mb-md-0'>
                    <Form.Label>Longitude</Form.Label>
                     <Form.Control placeholder='Enter Longitude' value={long} className='me-3'
                      onChange={(e)=>{ 
                        if (e.target.value.match(/^[0-9.]+$/)) {
                            setLong(e.target.value);
                        } else if (e.target.value.length === 0) {
                          setLong(e.target.value);
                        }
                        e.target.value.length > 0 ? setLongErr(false) : setLongErr(true);
                    }} />
                    { longErr ? <p className='errMsg'>Please enter valid longitude</p> : '' }
                  </Col>

                    <Col md={2} className='mb-3 mb-md-0'>
                    <Form.Label>Distance (Km)</Form.Label>
                     <Form.Control placeholder='Enter distance' value={area} className='me-3'
                        onChange={(e)=>{ 
                          if (e.target.value.match(/^[0-9.]+$/)) {
                              setArea(e.target.value);
                          } else if (e.target.value.length === 0) {
                            setArea(e.target.value);
                          }
                          e.target.value.length > 0 ? setAreaErr(false) : setAreaErr(true);
                      }} />
                    { areaErr ? <p className='errMsg'>Please enter valid distance</p> : '' }
                    </Col>

            <Col md={1} className={latErr || longErr || areaErr ? 'd-flex align-items-center mb-3 mb-md-0' 
                                  : 'd-flex align-items-end mb-3 mb-md-0'}>
              <Button className='primaryBtn pt-auto' onClick={searchByLatLongArea}>
                  <Icon.Search className='me-2' size={15}/>Search
              </Button>
            </Col>

           {
              data && !loader &&

              <Col md={3} className={latErr || longErr || areaErr ? 'd-flex align-items-center justify-content-center justify-content-md-start' 
                                  : 'd-flex align-items-end justify-content-center justify-content-md-start'}>
                <Button className='secondaryBtn ms-0 ms-md-4' onClick={downloadNearbyExcel}>
                  <Icon.Download className='me-2'/>Download Excel
                </Button>
              </Col>
            }

        </Row>


            <Row className="outer-wrapper mx-auto mt-2">   
              {
                (!data && !loader) &&
                <Col md={11}> <img src={NearBy} alt="NearBy" className='img-fluid' data-aos='zoom-in'/> </Col>
              }

            {
               (!data || data) && loader ? 
                    <div style={{height:'65vh'}} className='d-flex justify-content-center align-items-center'>
                         <center><Lottie options={NearbyLoader} className='mt-3' width={300}/>Loading ...</center>
                       </div>
                   :
                  data && !loader &&
                   <> 
                    <div className="table-wrapper mt-2" style={{maxHeight:'68vh'}}>
                        <table data-aos="fade-up" data-aos-delay="200">
                            <thead>
                                <th>Sr. No.</th>
                                <th>Property Code</th>
                                <th>Property Name</th>
                                <th>Zone</th>
                                <th>Gat</th>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th>Verification Person</th>
                                <th>Verified Status</th>
                                <th>Reject Reason</th>
                                <th>Verified Date</th>
                                <th>Survey Date</th>
                                <th>Owner Name</th>
                                <th>Owner Mobile</th>
                                <th>Business Address</th>
                                <th>Final Business Address</th>
                            </thead>
                
                {
                    data?.data!== null && data?.data !== undefined && data?.data?.length > 0 ? data?.data?.map((itm, index) => {

                    const { propertyCode, propertyName, zonename, zoneno, gatname, lat, long,
                            verifiedByName, verifiedByMobile, isVerified, notVerifiedReason, verifiedDate, surveyDate, 
                            ownerName, ownerMobile, businessAddress, finalBusinessAddress } = itm

                            return (
                                <tr key={Math.random() * 999999999}>
                                    <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                    <td>{propertyCode? propertyCode :'-'}</td>
                                    <td style={{width:'250px'}}>{propertyName? propertyName:'-'}</td>
                                    <td>{zonename ? zonename +' - '+ zoneno : '-'}</td>
                                    <td>{ gatname ? gatname :'-'}</td>
                                    <td>{lat}</td>
                                    <td>{long}</td>
                                    <td style={{width:'250px'}}>{verifiedByName ? verifiedByName + ' - ' + verifiedByMobile :'-'}</td>
                                    <td>{isVerified!==undefined ? isVerified=== true ? 'Yes' : 'No' :'-'}</td>
                                    <td>{notVerifiedReason ? notVerifiedReason :'-'}</td>
                                    <td>{verifiedDate ? moment(verifiedDate).format('DD-MM-YYYY') :'-'}</td>
                                    <td>{surveyDate ? moment(surveyDate).format('DD-MM-YYYY') :'-'}</td>
                                    <td style={{width:'250px'}}>{ownerName ? ownerName:'-'}</td>
                                    <td>{ownerMobile ? ownerMobile :'-'}</td>
                                    <td style={{width:'250px'}}>{businessAddress ? businessAddress :'-'}</td>
                                    <td style={{width:'250px'}}>{finalBusinessAddress ? finalBusinessAddress :'-'}</td>
                                </tr>
                            )
                         })
                  : <p className='noDataFound'>No Data Found</p>
                }   
                  </table>
                    </div>
              

            <Row>
                <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
                    <h6 className='text-start mb-0'>
                        <Icon.People className='me-2'/>Total - <strong>{data?.count}</strong>
                    </h6>
                </Col>
                <Col md={8} className='d-flex justify-content-end p-md-0'>
                    <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange} 
                    onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage}/>
                </Col>
            </Row>
        </>
    }
            </Row>
        </Container>
    </div>
  )
}

export default NearbyReport