import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Spinner, Row } from "react-bootstrap";
import ReactS3 from "../../Utils/ReactS3";
import { useLocation, useNavigate } from "react-router-dom";

const Formtwo = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const ownerFormId = location.state


  const queryParams = new URLSearchParams(location.search);
  const [label, setlabel] = useState("");

  const [selectedLanguage, setSelectedLanguage] = useState('marathi');

  const [dropdown, setdropdown] = useState("");
  const [loader, setloader] = useState(false);
  const [isSatha, setisSatha] = useState(false);
  const [isVyavsay, setisVyavsay] = useState(false);
  const [isSathaParvana, setisSathaParvana] = useState(false);
  const [sathaParvanaDate, setsathaParvanaDate] = useState();
  const [sathaParvanaImage, setsathaParvanaImage] = useState([]);
  const [sathaParvanaNumber, setsathaParvanaNumber] = useState();
  const [isSathaShopActLicense, setisSathaShopActLicense] = useState(false);
  const [sathaShopActLicenseImage, setsathaShopActLicenseImage] = useState([]);
  const [sathaShopActNumber, setsathaShopActNumber] = useState();
  const [isBusinessParvana, setisBusinessParvana] = useState(false);
  const [businessParvanaDate, setbusinessParvanaDate] = useState();
  const [businessParvanaImage, setbusinessParvanaImage] = useState([]);
  const [businessParvanaNumber, setbusinessParvanaNumber] = useState();
  const [isFoodLicense, setisFoodLicense] = useState(false);
  const [isFoodLicenseDate, setisFoodLicenseDate] = useState();
  const [isFoodLicenseNumber, setisFoodLicenseNumber] = useState();
  const [isBusinessShopActLicense, setisBusinessShopActLicense] =
    useState(false);
  const [isFoodLicenseImage, setisFoodLicenseImage] = useState([]);
  const [businessShopActLicenseImage, setbusinessShopActLicenseImage] =
    useState([]);
  const [surveyById, setsurveyById] = useState();
  const [surveyByName, setsurveyByName] = useState();
  const [surveyByMobile, setsurveyByMobile] = useState();
  const [s3Data1, sets3Data1] = useState("");
  const getsW3credential = async () => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/credentials/getCredentials", {
        projectName: "PCMC_SURVEY",
      })
      .then((res) => {
        sets3Data1(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getIntialValue = async () => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/surveys/getSurveyBySurveyId", {
        surveyId: queryParams.get('surveyId'),
      })
      .then((res) => {
        setloader(false);
        setdropdown(res.data.dropdownValues);
        if (res.data.page2) {
          const e = res.data.page2;
          setisSatha(e.isSatha);
          setisVyavsay(e.isVyavsay);
          setisSathaParvana(e.isSathaParvana);
          setsathaParvanaDate(e.sathaParvanaDate);
          setsathaParvanaImage(e.sathaParvanaImage);
          setsathaParvanaNumber(e.sathaParvanaNumber);
          setisSathaShopActLicense(e.isSathaShopActLicense);
          setsathaShopActLicenseImage(e.sathaShopActLicenseImage);
          setsathaShopActNumber(e.sathaShopActNumber);
          setisBusinessParvana(e.isBusinessParvana);
          setbusinessParvanaDate(e.businessParvanaDate);
          setbusinessParvanaImage(e.businessParvanaImage);
          setbusinessParvanaNumber(e.businessParvanaNumber);
          setisFoodLicense(e.isFoodLicense);
          setisFoodLicenseDate(e.isFoodLicenseDate);
          setisFoodLicenseNumber(e.isFoodLicenseNumber);
          setisBusinessShopActLicense(e.isBusinessShopActLicense);
          setisFoodLicenseImage(e.isFoodLicenseImage);
          setbusinessShopActLicenseImage(e.businessShopActLicenseImage);
          setsurveyById(e.surveyId);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };

  const handleIsSathaShopActLicenseRadioChange = (e) => {
    setisSathaShopActLicense(e.target.value === 'true');
  };

  const handleIsBusinessParvanaRadioChange = (e) => {
    setisBusinessParvana(e.target.value === 'true');
  };

  const handleIsBusinessShopActLicensRadioChange = (e) => {
    setisBusinessShopActLicense(e.target.value === 'true');
  };


  const getLebal = async () => {
    setloader(true);
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/labels/getLabels")
      .then((res) => {
        if (res.data.data) {
          setlabel(res.data.data);
          getIntialValue();
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const getdropdownvalue = async () => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/dropdownValues/getAllDropdownValues",
        {
          propertyCode: "1020100097.00",
          user_id: "",
        }
      )
      .then((res) => {
        if (res.data.dropdownValues) {
          setloader(false);
          setdropdown(res.data.dropdownValues);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getsW3credential();
    getLebal();
  }, []);

  const s3Data = {
    projectName: "itgoa",
    bucketName: "it-goa",
    region: "ap-south-1",
    accessKeyId: "AKIA4GWLDQ2LL4DLFWEF",
    secretAccessKey: "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",
    s3Url: "https://it-goa.s3.ap-south-1.amazonaws.com",
  };

  const handleProfileImg = async (e) => {
    let result = await ReactS3(e.target.files[0], s3Data, "citizen");

    return result;
  };
  const UpdatePage2 = async (e) => {
    e.preventDefault();
    // if () {

    // } else {

    const payload = {
      "ownerFormId": ownerFormId?.ownerFormId,
      "isSatha": isSatha,
      "isVyavsay": isVyavsay,
      "isSathaParvana": isSathaParvana,
      "sathaParvanaDate": sathaParvanaDate,
      "sathaParvanaImage": sathaParvanaImage,
      "sathaParvanaNumber": sathaParvanaNumber,
      "isSathaShopActLicense": isSathaShopActLicense,
      "sathaShopActLicenseImage": sathaShopActLicenseImage,
      "sathaShopActNumber": sathaShopActNumber,
      "isBusinessParvana": isBusinessParvana,
      "businessParvanaDate": businessParvanaDate,
      "businessParvanaImage": businessParvanaImage,
      "businessParvanaNumber": businessParvanaNumber,
      "isBusinessShopActLicense": isBusinessShopActLicense,
      "businessShopActLicenseImage": businessShopActLicenseImage,
      // "businessShopActNumber": businessShopActNumber,
      "isFoodLicense": isFoodLicense,
      "isFoodLicenseDate": isFoodLicenseDate,
      "isFoodLicenseNumber": isFoodLicenseNumber,
      "isFoodLicenseImage": isFoodLicenseImage
    }

    await axios
      .post(process.env.REACT_APP_BASE_URL + "/ownerform/updateOwnerFormPage2", payload)
      .then((res) => {
        console.log("res", res)
        navigation(`/CitizenLayout/Formthree?surveyId=${queryParams.get('surveyId')}`, { state: { ownerFormId: ownerFormId?.ownerFormId } });

      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  const getLabelName = (field) => {
    const languageObject = selectedLanguage === 'marathi' ? label?.page1 : label?.page1Eng;

    const languageObjectKey = selectedLanguage === 'marathi' ? field : `${field}Eng`;

    return languageObject?.[languageObjectKey] || '';
  };

  return (
    <div style={{ padding: 20, display: "flex", justifyContent: "center" }}>

      {
        loader ? <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
          :
          <Form className="form-layout">

            <Row>
              <h1 className="text-center">Form 2</h1>
            </Row>

            <Form.Label className="Label-muted">
              {getLabelName('businessCategory')}
            </Form.Label>

            <Form.Group
              key={`radio`}
              className="mb-4"
              onChange={(e) => {
                if (e.target.id == "साठा" && e.target.checked) {
                  setisSatha(true);
                } else if (e.target.id == "साठा" && !e.target.checked) {
                  setisSatha(false);
                }

                if (e.target.id == "व्यवसाय" && e.target.checked) {
                  setisVyavsay(true);
                } else if (e.target.id == "व्यवसाय" && !e.target.checked) {
                  setisVyavsay(false);
                }
              }}
            >
              <Form.Check
                inline
                label={selectedLanguage === 'marathi' ? "साठा" : "Go-down"}
                name="group1"
                type={"checkbox"}
                id={"साठा"}
                checked={isSatha}
              />
              <Form.Check
                inline
                label={selectedLanguage === 'marathi' ? "व्यवसाय" : "Bussiness"}
                name="group1"
                type={"checkbox"}
                id={"व्यवसाय"}
                checked={isVyavsay}
              />
            </Form.Group>


            <div style={{ display: isSatha ? "block" : "none" }}>
              <Form.Label className="Label-muted">
                {getLabelName('isSathaShopAct')}
              </Form.Label>
              <Form.Group
                key={`radio`}
                className="mb-4"
                onChange={(e) => {
                  setisSathaShopActLicense(e.target.id == "true" ? true : false);
                }}
              >
                <Form.Check
                  checked={isSathaShopActLicense === true}
                  inline
                  label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                  name="group3"
                  type={"radio"}
                  id={"true"}
                  onChange={handleIsSathaShopActLicenseRadioChange}

                />
                <Form.Check
                  checked={isSathaShopActLicense === false}
                  inline
                  label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                  name="group3"
                  type={"radio"}
                  id={"false"}
                  onChange={handleIsSathaShopActLicenseRadioChange}
                />
              </Form.Group>


            </div>


            <div style={{ display: isVyavsay ? "block" : "none" }}>
              <Form.Group className="mb-4 " controlId="formBasicEmail">
                <Form.Label>{getLabelName('businessParvana')}</Form.Label>
              </Form.Group>

              <Form.Label className="Label-muted">
                {getLabelName('isBusinessParvana')}
              </Form.Label>

              <Form.Group
                key={`radio`}
                className="mb-4"
                onChange={(e) => {
                  setisBusinessParvana(e.target.id == "true" ? true : false);
                }}
              >
                <Form.Check
                  checked={isBusinessParvana === true}
                  inline
                  label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                  name="group4"
                  type={"radio"}
                  id={"true"}
                  onChange={handleIsBusinessParvanaRadioChange}
                />
                <Form.Check
                  checked={isBusinessParvana === false}
                  inline
                  label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                  name="group4"
                  type={"radio"}
                  id={"false"}
                  onChange={handleIsBusinessParvanaRadioChange}
                />
              </Form.Group>


              <Form.Label className="Label-muted">
                {getLabelName('isBusinessShopAct')}
              </Form.Label>
              <Form.Text style={{ color: "red", marginLeft: 10 }}>*</Form.Text>
              <Form.Group
                key={`radio`}
                className="mb-4"
                onChange={(e) => {
                  setisBusinessShopActLicense(
                    e.target.id == "true" ? true : false
                  );
                }}
              >
                <Form.Check
                  checked={isBusinessShopActLicense === true}
                  inline
                  label={selectedLanguage === 'marathi' ? "होय" : "Yes"}
                  name="group5"
                  type={"radio"}
                  id={"true"}
                  onChange={handleIsBusinessShopActLicensRadioChange}
                />
                <Form.Check
                  checked={isBusinessShopActLicense === false}
                  inline
                  label={selectedLanguage === 'marathi' ? "नाही" : "No"}
                  name="group5"
                  type={"radio"}
                  id={"false"}
                  onChange={handleIsBusinessShopActLicensRadioChange}
                />
              </Form.Group>
            </div>

            <div className="d-flex justify-content-end">
              <Button
                className="primaryBtn"
                onClick={(e) => {
                  UpdatePage2(e);
                }}
              >
                Next
              </Button>
            </div>

          </Form>
      }
    </div>
  )
}

export default Formtwo