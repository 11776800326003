import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import SuccessImg from '../../Assets/Success.svg'
import { useLocation } from 'react-router-dom';

const SubmitSuccess = () => {
    const location = useLocation();
    const formSubmitStatus = location.state

    return (
        <div className='SubmitSuccess'>
            <Container>
                <Row className='justify-content-center' data-aos="fade-up">
                    <Col md={10} className='text-center'>
                        <img src={SuccessImg} className='img-fluid' alt="Page Not Found" />
                        <h1>Survey Form Submitted Successfully !</h1>
                        <p>* Your all forms submitted successfully, wait for further notification by pcmc survey department</p>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SubmitSuccess;