import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';

const Formone = () => {

  const navigation = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [selectedLanguage, setSelectedLanguage] = useState('english');

  const [label, setlabel] = useState("");
  const [dropdown, setdropdown] = useState("");
  const [loader, setloader] = useState(false);
  const [subpropertyType, setsubpropertyType] = useState([]);

  const [buildingFloors, setbuildingFloors] = useState("");
  const [buildingFloorsError, setbuildingFloorsError] = useState("");

  const [propertyFloor, setpropertyFloor] = useState("");
  const [propertyFloorError, setpropertyFloorError] = useState("");

  const [propertyAreaFoot, setpropertyAreaFoot] = useState("");
  const [propertyAreaFootError, setpropertyAreaFootError] = useState("");

  const [propertyAreaMeter, setpropertyAreaMeter] = useState("");
  const [propertyAreaMeterError, setpropertyAreaMeterError] = useState("");

  const [propertyWorkPlace, setpropertyWorkPlace] = useState("");
  const [propertyWorkPlaceError, setpropertyWorkPlaceError] = useState("");


  const [rentedPersonName, setrentedPersonName] = useState("");
  const [rentedPersonNameError, setrentedPersonNameError] = useState("");

  const [rentedPersonMobile, setrentedPersonMobile] = useState("");
  const [rentedPersonMobileError, setrentedPersonMobileError] = useState("");


  const [businessName, setBusinessName] = useState("");



  const [addressLandmark, setaddressLandmark] = useState("");
  const [addressFloor, setaddressFloor] = useState("");
  const [addressRoadColony, setaddressRoadColony] = useState("");

  const [propertyType, setpropertyType] = useState("");
  const [propertyTypeId, setpropertyTypeId] = useState("");
  const [subPropertyType, setsubPropertyType] = useState("");
  const [subPropertyTypeId, setsubPropertyTypeId] = useState("");
  const [ownerName, setownerName] = useState("");
  const [businessAddress, setbusinessAddress] = useState("");
  const [finalBusinessAddress, setfinalBusinessAddress] = useState("");
  const [ownerMobile, setownerMobile] = useState("");
  const [ownerEmail, setownerEmail] = useState("");
  const [isRented, setisRented] = useState(false);

  const [businessStartDate, setbusinessStartDate] = useState("");
  const [surveyById, setsurveyById] = useState("");

  const [subPropertyTypeOther, setsubPropertyTypeOther] = useState("");
  const [surveyByName, setsurveyByName] = useState("");
  const [surveyByMobile, setsurveyByMobile] = useState("");
  // const [surveyId, setsurveyId] = useState("");
  const [ownerFormId, setOwnerFormId] = useState("");






  useEffect(() => {
    // getAllpropertyType();
    getSubpropertyTypeList()
    getLebal();
    addSurvey()
  }, []);

  const addSurvey = async () => {

    try {
      axios.post(process.env.REACT_APP_BASE_URL + "/ownerform/addOwnerForm", { "surveyId": queryParams.get('surveyId') })
        .then((response) => {
          console.log("response", response?.data?.data)
          setOwnerFormId(response?.data?.data?.ownerFormId)
        })
        .catch((error) => {
          console.log("Error", error)
        })

    } catch (error) {
      console.log("Error", error)
    }
  }

  const getLebal = async () => {
    setloader(true);
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/labels/getLabels")
      .then((res) => {
        if (res.data.data) {
          setlabel(res.data.data);
          getIntialValue();
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const getdropdownvalue = async () => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/dropdownValues/getAllDropdownValues",
        {
          propertyCode: "1020100097.00",
          user_id: "",
        }
      )
      .then((res) => {
        if (res.data.dropdownValues) {
          setloader(false);

          setdropdown(res.data.dropdownValues);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };


  const handleRented = () => {
    setrentedPersonMobile("")
    setrentedPersonName("")
    setrentedPersonNameError("")
    setrentedPersonMobileError("")
  }

  const UpadtePage1 = async (e) => {

    e.preventDefault();

    if (!buildingFloors) {
      setbuildingFloorsError("Please Enter Building Floor");
    } else if (!propertyFloor) {
      setbuildingFloorsError("");
      setpropertyFloorError("Please Enter Property Floor");
    } else if (!propertyAreaFoot) {
      setbuildingFloorsError("");
      setpropertyFloorError("");
      setpropertyAreaFootError("Please Enter Property Area Foot");
    } else if (!propertyAreaMeter) {
      setbuildingFloorsError("");
      setpropertyFloorError("");
      setpropertyAreaFootError("");
      setpropertyAreaMeterError("Please Enter Property Area Meter");
    } else if (!propertyWorkPlace) {
      setbuildingFloorsError("")
      setpropertyFloorError("")
      setpropertyAreaFootError("")
      setpropertyAreaMeterError("")
      setpropertyWorkPlaceError("Please Enter Property Work Place")
    } else if (isRented && !rentedPersonName) {
      setbuildingFloorsError("")
      setpropertyFloorError("")
      setpropertyAreaFootError("")
      setpropertyAreaMeterError("")
      setpropertyWorkPlaceError("")
      setrentedPersonNameError("Please Enter Rented Person Name")
    } else if (isRented && !rentedPersonMobile) {
      setbuildingFloorsError("")
      setpropertyFloorError("")
      setpropertyAreaFootError("")
      setpropertyAreaMeterError("")
      setpropertyWorkPlaceError("")
      setrentedPersonNameError("")
      setrentedPersonMobileError("Please Enter Rented Person Mobile")
    } else {
      setbuildingFloorsError("")
      setpropertyFloorError("")
      setpropertyAreaFootError("")
      setpropertyAreaMeterError("")
      setpropertyWorkPlaceError("")
      setrentedPersonNameError("")
      setrentedPersonMobileError("")

      const payload = {
        "ownerFormId": ownerFormId,
        "propertyFloor": propertyFloor,
        "buildingFloors": buildingFloors,
        "propertyAreaMeter": propertyAreaMeter,
        "propertyAreaFoot": propertyAreaFoot,
        "addressLandmark": addressLandmark,
        "addressFloor": addressFloor,
        "businessName": businessName,
        "addressRoadColony": addressRoadColony,
        "propertyWorkPlace": propertyWorkPlace,
        "propertyType": propertyType,
        "propertyTypeId": propertyTypeId,
        "subPropertyType": subPropertyType,
        "subPropertyTypeOther": subPropertyTypeOther,
        "subPropertyTypeId": subPropertyTypeId,
        "ownerName": ownerName,
        "businessAddress": businessAddress,
        "finalBusinessAddress": finalBusinessAddress,
        "ownerMobile": ownerMobile,
        "ownerEmail": ownerEmail,
        "isRented": isRented,
        "rentedPersonName": rentedPersonName,
        "rentedPersonMobile": rentedPersonMobile,
        "businessStartDate": businessStartDate
      }

      await axios
        .post(process.env.REACT_APP_BASE_URL + "/ownerform/updateOwnerFormPage1", payload)
        .then((res) => {
          navigation(`/CitizenLayout/Formtwo?surveyId=${queryParams.get('surveyId')}`, { state: { ownerFormId } });

        })
        .catch((err) => {
          console.log(err);
        });
    }

  };
  const getIntialValue = async () => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/surveys/getSurveyBySurveyId", {
        // surveyId: "SURVEY_1110100008_042023032024_9359330702",
        surveyId: queryParams.get('surveyId')
      })
      .then((res) => {
        setloader(false);
        setdropdown(res.data.dropdownValues);
        if (res.data.page1) {
          setpropertyFloor(res.data.page1.propertyFloor);
          setbuildingFloors(res.data.page1.buildingFloors);
          setpropertyAreaMeter(res.data.page1.propertyAreaMeter);
          setpropertyAreaFoot(res.data.page1.propertyAreaFoot);
          setaddressLandmark(res.data.page1.addressLandmark);
          setaddressFloor(res.data.page1.addressFloor);
          setaddressRoadColony(res.data.page1.addressRoadColony);
          setpropertyWorkPlace(res.data.page1.propertyWorkPlace);
          setpropertyType(res.data.page1.propertyType);
          setpropertyTypeId(res.data.page1.propertyTypeId);
          setsubPropertyType(res.data.page1.propertyType);
          setownerName(res.data.page1.ownerName);
          setbusinessAddress(res.data.page1.businessAddress);
          setfinalBusinessAddress(res.data.page1.finalBusinessAddress);
          setownerMobile(res.data.page1.ownerMobile);
          setownerEmail(res.data.page1.ownerEmail);
          setisRented(res.data.page1.isRented);
          setrentedPersonName(res.data.page1.rentedPersonName);
          setrentedPersonMobile(res.data.page1.rentedPersonMobile);
          setbusinessStartDate(res.data.page1.businessStartDate);
          setsurveyById(res.data.page1.surveyId);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };

  const getSubpropertyTypeList = async () => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/spt/getAllSubPropertyType")
      .then((res) => {
        setsubpropertyType(res.data.data);
      })
      .catch((err) => {
        console.log("errrr", err);
      });
  }

  // const setTitle = () => {
  //   return { languageEng? label?.page1Eng?.buildingFloorEng: label?.page1?.buildingFloor }
  // }

  // const getLabel = (page, field) => {
  //   return label[selectedLanguage]?.[page]?.[field] || '';
  // };


  const getLabelName = (field) => {
    const languageObject = selectedLanguage === 'marathi' ? label?.page1 : label?.page1Eng;

    const languageObjectKey = selectedLanguage === 'marathi' ? field : `${field}Eng`;

    return languageObject?.[languageObjectKey] || '';
  };


  return (
    <div className="form1 my-4">
      <Container>
        <h1 className='text-center'>General Information</h1>

        {
          loader ? <Spinner variant="primary" /> :
            <Form >
              <Row>
                <Col md={6} className="mb-4">
                  <Form.Group>
                    <Form.Label>{getLabelName('buildingFloor')}</Form.Label>
                    <Form.Control value={buildingFloors}
                      onChange={(e) => { setbuildingFloors(e.target.value); }} type="number"
                      placeholder={getLabelName('buildingFloor')} />
                  </Form.Group>
                  {buildingFloorsError && <Form.Text className="text-danger">{buildingFloorsError}</Form.Text>}
                </Col>

                <Col md={6} className="mb-4">
                  <Form.Group >
                    <Form.Label>{getLabelName('propertyFloor')}</Form.Label>
                    <Form.Control value={propertyFloor} onChange={(e) => { setpropertyFloor(e.target.value); }}
                      type="number" placeholder={getLabelName('propertyFloor')} />
                  </Form.Group>
                  {propertyFloorError && <Form.Text className="text-danger">{propertyFloorError}</Form.Text>}
                </Col>

                <Col md={6}>
                  <Form.Label>{getLabelName('propertyAreaHeader')}</Form.Label>
                  <div className='d-flex'>
                    <Form.Group className='me-3' as={Col} onChange={(e) => { setpropertyAreaFoot(e.target.value) }}>
                      <Form.Label>{getLabelName('propertyAreaFoot')} </Form.Label>
                      <Form.Control value={propertyAreaFoot} type="number" placeholder={getLabelName('propertyAreaFoot')} />
                      {propertyAreaFootError && <Form.Text className="text-danger">{propertyAreaFootError}</Form.Text>}
                    </Form.Group>

                    <Form.Group as={Col} onChange={(e) => { setpropertyAreaMeter(e.target.value) }}>
                      <Form.Label>{getLabelName('propertyAreaMeter')}</Form.Label>
                      <Form.Control value={propertyAreaMeter} type="number" placeholder={getLabelName('propertyAreaMeter')} />
                      {propertyAreaMeterError && <Form.Text className="text-danger">{propertyAreaMeterError}</Form.Text>}
                    </Form.Group>
                  </div>
                </Col>

                <Col md={6}>
                  <Form.Group
                    key={`radio`}
                    className="mb-4"
                    onChange={(e) => {
                      setpropertyWorkPlace(e.target.id);
                    }}
                  >
                    <Form.Label className="Label-muted">
                      {getLabelName('propertyWorkPlace')}
                    </Form.Label>
                    <Form.Check
                      label={getLabelName('MIDC')}
                      name="group1"
                      type={"radio"}
                      id={label?.page1?.MIDC}
                      value={propertyWorkPlace}
                      checked={propertyWorkPlace === label?.page1?.MIDC}
                    />
                    <Form.Check
                      label={getLabelName('PCMC')}
                      name="group1"
                      type={"radio"}
                      id={label?.page1?.PCMC}
                      value={propertyWorkPlace}
                      checked={propertyWorkPlace === label?.page1?.PCMC}
                    />
                    <Form.Check
                      label={getLabelName('PMRDA')}
                      name="group1"
                      type={"radio"}
                      id={label?.page1?.PMRDA}
                      value={propertyWorkPlace}
                      checked={propertyWorkPlace === label?.page1?.PMRDA}
                    />
                    {propertyWorkPlaceError && <Form.Text className="text-danger">{propertyWorkPlaceError}</Form.Text>}
                  </Form.Group>




                </Col>

                {/* <Form.Group className="mb-4">
                  <Form.Label className="Label-muted ">
                    {label?.page1?.propertyType}
                  </Form.Label>

                  <Form.Select
                    onChange={(e) => {
                      setpropertyTypeId(JSON.parse(e.target.value).propertyTypeId);
                      setpropertyType(JSON.parse(e.target.value).propertyType);
                      getSubpropertyType(JSON.parse(e.target.value).propertyTypeId);
                    }}
                  >
                    <option value="">Please Select</option>
                    {properytyType?.length > 0 &&
                      properytyType.map((item) => {
                        return (
                          <option
                            label={item.propertyType}
                            value={JSON.stringify(item)}
                            key={item}
                          ></option>
                        );
                      })}
                  </Form.Select>
                </Form.Group> */}

                <Form.Group
                  className="mb-4"
                  onChange={(e) => {
                    setsubPropertyType(JSON.parse(e.target.value).subPropertyType);
                    setsubPropertyTypeId(
                      JSON.parse(e.target.value).subPropertyTypeId
                    );
                  }}
                // style={{ display: propertyTypeLabel?.propertyTypeLabel !== "" ? "block" : "none" }}
                >
                  <Form.Label className="Label-muted ">
                    {getLabelName('businessType')}
                    {/* {propertyTypeLabel?.propertyTypeLabel} */}
                  </Form.Label>
                  <Form.Select aria-label="Default select example">
                    {subpropertyType.length > 0 &&
                      subpropertyType.map((item) => {
                        return (
                          <option value={JSON.stringify(item)}>
                            {item.subPropertyType}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>

                {/* <Form.Group
                  style={{ display: subPropertyType == "इतर" ? "block" : "none" }}
                  className="mb-4"
                  controlId="formBasicEmail"
                >
                  <Form.Label>{"इतर"}</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setsubPropertyTypeOther(e.target.value);
                    }}
                    value={subPropertyTypeOther}
                    type="name"
                    placeholder={"इतर"}
                  />
                </Form.Group> */}


                <Col md={12}>
                  <Form.Group className="mb-4">
                    <Form.Label>{getLabelName('businessName')}</Form.Label>
                    <Form.Control value={businessName} onChange={(e) => { setBusinessName(e.target.value); }}
                      type="text" placeholder={getLabelName('businessName')} />
                  </Form.Group>
                </Col>

                <Form.Group className="mb-4" >
                  <Form.Label>{getLabelName('ownerName')} </Form.Label>
                  <Form.Control
                    value={ownerName}
                    onChange={(e) => {
                      setownerName(e.target.value);
                    }}
                    type="name"
                    placeholder={getLabelName('ownerName')}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>{getLabelName('finalBusinessAddress')}</Form.Label>
                  <Form.Control
                    value={finalBusinessAddress}
                    onChange={(e) => {
                      setfinalBusinessAddress(e.target.value);
                    }}
                    type="name"
                    placeholder={getLabelName('finalBusinessAddress')}
                  />
                </Form.Group>


                {/* <Form.Group className="mb-4" >
                  <Form.Label>{label?.page1?.addressFloor}</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setaddressFloor(e.target.value);
                    }}
                    type="name"
                    value={addressFloor}
                    placeholder={label?.page1?.addressFloor}
                  />
                </Form.Group> */}

                <Form.Group className="mb-4">
                  <Form.Label>{getLabelName('addressRoadColony')}</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setaddressRoadColony(e.target.value);
                    }}
                    value={addressRoadColony}
                    type="name"
                    placeholder={getLabelName('addressRoadColony')}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>{getLabelName('addressLandmark')}</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setaddressLandmark(e.target.value);
                    }}
                    type="name"
                    value={addressLandmark}
                    placeholder={getLabelName('addressLandmark')}
                  />
                </Form.Group>

                {/*
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>{label?.page1?.businessAddress}</Form.Label>
                  <Form.Control
                    value={businessAddress}
                    onChange={(e) => {
                      setbusinessAddress(e.target.value);
                    }}
                    type="name"
                    placeholder={label?.page1?.businessAddress}
                  />
                </Form.Group>
                
                <Form.Group className="mb-4">
                  <Form.Label>{label?.page1?.finalBusinessAddress}</Form.Label>
                  <Form.Control
                    value={finalBusinessAddress}
                    onChange={(e) => {
                      setfinalBusinessAddress(e.target.value);
                    }}
                    type="name"
                    placeholder={label?.page1?.finalBusinessAddress}
                  />
                </Form.Group> */}

                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>{getLabelName('ownerMobile')}</Form.Label>
                  <Form.Control
                    value={ownerMobile}
                    onChange={(e) => {
                      setownerMobile(e.target.value);
                    }}
                    type="mobile"
                    placeholder={getLabelName('ownerMobile')}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>{getLabelName('ownerEmail')}</Form.Label>
                  <Form.Control
                    value={ownerEmail}
                    onChange={(e) => {
                      setownerEmail(e.target.value);
                    }}
                    type="email"
                    placeholder={getLabelName('ownerEmail')}
                  />
                </Form.Group>
                <Form.Label className="Label-muted">
                  {getLabelName('isRented')}
                </Form.Label>
                <Form.Group
                  key={`radio`}
                  className="mb-4"
                  onChange={(e) => {
                    setisRented(e.target.id == "true" ? true : false);
                    handleRented();
                  }}
                >
                  <Form.Check
                    checked={isRented == false}
                    inline
                    label={getLabelName('originalOwnerName')}
                    name="group2"
                    type={"radio"}
                    id={"false"}
                    value={isRented}
                  />
                  <Form.Check
                    checked={isRented == true}
                    inline
                    label={getLabelName('rentedOwnerName')}
                    name="group2"
                    type={"radio"}
                    id={"true"}
                    value={isRented}
                  />
                </Form.Group>

                <Form.Group
                  style={{ display: isRented ? "block" : "none" }}
                  className="mb-4"
                  controlId="formBasicEmail"
                >
                  <Form.Label>{getLabelName('rentedPersonName')}</Form.Label>
                  <Form.Control
                    value={rentedPersonName}
                    onChange={(e) => {
                      setrentedPersonName(e.target.value);
                    }}
                    type="name"
                    placeholder={getLabelName('rentedPersonName')}
                  />
                  {rentedPersonNameError && <Form.Text className="text-danger">{rentedPersonNameError}</Form.Text>}
                </Form.Group>
                <Form.Group
                  style={{ display: isRented ? "block" : "none" }}
                  className="mb-4"
                  controlId="formBasicEmail"
                >
                  <Form.Label>{getLabelName('rentedPersonMobile')}</Form.Label>
                  <Form.Control
                    value={rentedPersonMobile}
                    onChange={(e) => {
                      setrentedPersonMobile(e.target.value);
                    }}
                    type="number"
                    placeholder={getLabelName('rentedPersonMobile')}
                  />
                  {rentedPersonMobileError && <Form.Text className="text-danger">{rentedPersonMobileError}</Form.Text>}
                </Form.Group>
                <Form.Group
                  className="mb-4"
                  controlId="formBasicEmail"
                  onChange={(e) => {
                    setbusinessStartDate(e.target.value);
                  }}
                >
                  <Form.Label>{getLabelName('businessDate')}</Form.Label>
                  <Form.Control
                    value={businessStartDate}
                    type="date"
                    placeholder={getLabelName('businessDate')}
                  />
                </Form.Group>

                <div className="d-flex justify-content-end">
                  <Button
                    className="primaryBtn"
                    onClick={(e) => {
                      UpadtePage1(e);
                      // handleFormSubmit(e)
                    }}
                  >
                    Next
                  </Button>
                </div>

              </Row>
            </Form>
        }
      </Container>
    </div>
  )
}

export default Formone