import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner, Modal, InputGroup } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import { useSelector } from 'react-redux';
import Pagination from '../Common/Pagination';
import Skeleton from '../Common/Skeleton';

const ManageUsers = () => {

  const userState = useSelector(state=>state.surveylogin.userData?.data);
 
  const [data, setData] = useState('');

  const [searchMobileErr, setSearchMobileErr] = useState(false);
  const [loader, setLoader] = useState(false);
  const [paginate, setPaginate] = useState(true);

  const [showAdd, setShowAdd] = useState(false);

  const [name, setName] = useState('');
  const [nameErr, setNameErr] = useState(false);

  const [searchMobile, setSearchMobile] = useState('');
  const [mobile, setMobile] = useState('');
  const [mobileErr, setMobileErr] = useState(false);

  const [pass, setPass] = useState('');
  const [passErr, setPassErr] = useState(false);

  const [email, setEmail] = useState('')
  const [emailErr, setEmailErr] = useState(false)

  const [zone, setZone] = useState('')
  const [zoneList, setZoneList] = useState([])
  const [zoneErr, setZoneErr] = useState(false)

  const [role, setRole] = useState('');
  const [roleOptions, setRoleOptions] = useState([]);
  const [roleErr, setRoleErr] = useState(false);

  const [gat, setGat] = useState(null)
  const [gatList, setGatList] = useState([])
  const [gatErr, setGatErr] = useState(false)

  const [showEdit, setShowEdit] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const [showDel, setShowDel] = useState(false);
  const [userId, setUserId] = useState('');

    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(50);
    const [noOfPages, setNoOfPages] = useState();

   
    // function for pagination
           
      const handlePageChange = (pageNumber) => {
           setPageNo(pageNumber);
       };
       
       const handleDocsPerPage = (docsPageProp) => {
           setPageNo(1)
           setDocPerPage(docsPageProp);
       }

   useEffect(()=>{
    getAllUsers();
   },[pageNo, docPerPage])

  useEffect(()=>{
    getZone();
    getRoles();
  },[])

  useEffect(()=>{
    if(zone?.value){
      getGat();
    }
  },[zone?.value])

  //----- Setting up Closing modal & Clearing Values ----//
    const closeModal=()=>{
        setUserId("");
        setName("");
        setMobile("");
        setPass("");
        setEmail("");
        setRole('');
        setZone("");
        setGat("");

        setNameErr(false);
        setMobileErr(false);
        setPassErr(false);
        setEmailErr(false)
        setZoneErr(false)
        setGatErr(false)

        setShowAdd(false);
        setShowEdit(false);
        setShowDel(false);
    }

  const getAllUsers = async()=>{
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/users/getAllUsers',{
      method:'POST',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({ 
        mobile:"", 
        userType:userState?.userType_keyname,
        docsPerPage: docPerPage,
        pageNo: pageNo
      })
    }).then((res)=>res.json()).then((response)=>{
      setData(response?.data);
      setNoOfPages(response?.data?.noOfPages);
      setLoader(false);
    }).catch((err)=>{
      console.log("Err while getting users", err);
      setLoader(false);
    })
  }

  const getSingleUsers = async()=>{
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/users/getAllUsers',{
      method:'POST',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({ mobile:searchMobile})
    }).then((res)=>res.json()).then((response)=>{
      setData(response?.data);
      setPaginate(false);
      setLoader(false);
    }).catch((err)=>{
      console.log("Err while getting users", err);
      setLoader(false);
    })
  }

  
  /* ----------- Role API ------------------- */
  const getRoles= async()=>{
    await fetch(process.env.REACT_APP_BASE_URL + '/usertype/getAlluserType',{
      method:'POST',
      headers : {'Content-Type':'application/json'},
      body:JSON.stringify({  userType:userState?.userType_keyname})
    }).then((res)=>res.json()).then((response)=>{ 
      const roleOptions = response?.data?.map((itm) => ({
          value: itm.userType_keyname, label: itm.userType,
        }));
        setRoleOptions(roleOptions);
    }).catch((err)=>{
      console.log("err while getting roles", err);
    })
  }

  const getZone = async() =>{
    await fetch(process.env.REACT_APP_BASE_URL + '/zone/zoneList',{
      method:'GET',
      headers:{'Content-Type':'application/json'}
    }).then((res)=>res.json()).then((response)=>{
      setZoneList(response.data);
    }).catch((err)=>{
      console.log("Err while getting users", err);
    })
  }

  const zoneOption = zoneList !== undefined && zoneList !== null && zoneList.length > 0 ?
                     zoneList.map((itm) => ({
                      label: `${itm.zonename}-${itm.zoneno}`,
                      value: itm.zonekey,
  }))
  : [];


  const getGat = async() =>{
    await fetch(process.env.REACT_APP_BASE_URL + '/gat/gatList',{
      method:'POST',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({zonekey: zone?.value})
    }).then((res)=>res.json()).then((response)=>{
      setGatList(response.data);
    }).catch((err)=>{
      console.log("Err while getting users", err);
    })
  }


  const gatOption = gatList !== undefined && gatList !== null && gatList.length > 0
  ? gatList.map((itm) => ({
      label: itm.gatname,
      value: itm.gatkey,
    }))
  : [];


// --------------- Search User By Mobile ------------------------------
    const searchByValidate =()=>{
      let isValid = true;
      if(!searchMobile || searchMobile.length<10){
          isValid= false ;
          setSearchMobileErr(true);
      }
      return isValid;
  }

    const searchByMobile = async()=>{
      if(searchByValidate()){
        getSingleUsers();
      }
      else{
          setLoader(false);
      }
    }

/*---------------------------- ADD User Functions --------------------------------*/

    const addValidate =()=>{
      let isValid = true;
      if(!name){
        isValid = false;
        setNameErr(true);
      }
      else if(!mobile || mobile.length<10){
        isValid = false;
        setMobileErr(true);
      }
      // else if(!email || !email.includes("@.")){
      //   isValid = false;
      //   setEmailErr(true);
      // }
      else if(!pass){
        isValid = false;
        setPassErr(true);
      }
      else if(!role){
        isValid = false;
        setRoleErr(true)
      }
      else if(!zone){
        isValid = false;
        setZoneErr(true);
      }
      else if(!gat || gat.length === 0){
        isValid = false;
        setGatErr(true);
      }
      return isValid;
    }

    const addUser = async()=>{
      if (addValidate()) {
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/users/addUser', {
          method:'POST',
          headers:{'Content-Type':'application/json'},
          body:JSON.stringify({
            name: name,
            mobile: mobile,
            email : email,
            userType :role.value,
            zonekey:zone.value,
            gatkey: gat?.map((itm)=> itm?.value),
            password: pass
          })
        }).then((res)=>res.json()).then((response)=>{
          if(response.message === "User Added Successfully"){
            toast.success("User Added Successfully");
            setLoader(false);
            closeModal();
            getAllUsers();
          }else if(response.message === "User Already Exists"){
            toast.error("User Already Exists.");
            setLoader(false);
            closeModal();
          }
        }).catch((err)=>{
          console.log("Err while adding user", err);
          setLoader(false);
        })
      }
      else{
        setLoader(false);
      }
    }

/* --------------------------- UPDATE User Functions -----------------------------*/
    const setEditData=(itm)=>{
      setUserId(itm?.user_id);
      setName(itm?.name);
      setEmail(itm?.email);
      setRole({value:itm?.userType_name, label:itm?.userType_keyname});
      setZone({ value: itm?.zonekey, label: itm?.zonename });
      let gatData=[];
      for(let i=0; i<itm?.gatkey.length; i++) {
        gatData.push({
          value:itm?.gatkey[i], label:itm?.gatname[i]
        })
      }
      setGat(gatData);
    }

    const updateValidate =()=>{
      let isValid = true;
      if(!name){
        isValid = false;
        setNameErr(true);
      }
      // else if(!email){
      //   isValid = false;
      //   setEmailErr(true);
      // }
      else if(zone.length === 0){
        isValid = false;
        setZoneErr(true);
      }
      else if(gat.length === 0){
        isValid = false;
        setGatErr(true);
      }
      return isValid;
    }

  const updateUser = async()=>{
   
    if(updateValidate()){
      setLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + '/users/updateUser',{
        method:'POST',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify({
          user_id: userId,
          name: name,
          email : email,
          zonekey:zone.value,
          gatkey:gat?.map((itm)=> itm?.value.toString())
        })
      }).then((res)=>res.json()).then((response)=>{
        if(response.message === "User updated successfully"){
            setShowEdit(false);
            setMobile("");
            toast.success("User Updated Successfully");
            getAllUsers();
        }
      }).catch((err)=>{
          console.log("Err while updating user", err);
          setLoader(false);
      })
    }
  }

  /* ------------------------- DELETE User Functions -----------------------------*/
    const deleteUser=async()=>{
      setLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + '/users/deleteUser',{
        method:'POST',
        headers:{'Content-Type':'application/json'},
        body: JSON.stringify({"user_id": userId})
      }).then((res)=>res.json()).then((response)=>{
          if(response.message === "User deleted successfully"){
            closeModal();
            toast.success("User Deleted Successfully");
            getAllUsers();
          }
      }).catch((err)=>{
        console.log("Err while deleting user", err);
        setLoader(false);
      })
    }



  return (
    <div className='ManageUsers outletPadding'>
      <ToastContainer/>
        <Container>
            <Row className='justify-content-between'>
              <Col md={3}>
                  <div className='d-flex'>
                  <Form.Control placeholder='Enter Mobile No.' value={searchMobile} className='me-3'
                    onChange={(e)=>{ 
                      if(e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10){
                        setSearchMobile(e.target.value)
                      }
                      else if (e.target.value.length === 0){
                        setSearchMobile(e.target.value)
                      }
                  e.target.value.length>0 ? setSearchMobileErr(false): setSearchMobileErr(true); }} />
                  <Button className='primaryBtn' onClick={searchByMobile}>
                      <Icon.Search className='me-2' size={15}/>Search
                  </Button>
                  </div>
              { searchMobileErr ? <p className='errMsg'>Please enter valid mobile no.</p> : '' }
            </Col>

            <Col md={2} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
                  <Button className='primaryBtn' onClick={()=>setShowAdd(true)}>
                      <Icon.PlusCircle className='me-2' size={15}/>Add User
                  </Button>
            </Col>
        </Row>


            <div className="outer-wrapper mx-auto mt-4">
              <div className="table-wrapper" style={{maxHeight:'70vh'}}>
                <table>
                  <thead>
                    <th>Sr. No.</th>
                    <th>Name</th>
                    <th>Mobile Number</th>
                    <th>Email ID</th>
                    <th>Role</th>
                    <th>Zone</th>
                    <th>Gat</th>
                    <th>Actions</th>
                  </thead>
                {
                    loader ? <Skeleton rows={8} cols={8}/> : 
                    data?.users!== null && data.users !== undefined && data?.users.length > 0 ? data?.users.map((itm, index) => {
                      const { user_id, name, mobile, userType_name, zoneno, gatname, email} = itm
                        return (
                        
                          <tr key={Math.random() * 999999999}>
                            <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                            <td style={{width:'18vw'}}>{name}</td>
                            <td>{mobile}</td>
                            <td>{email?email:'-'}</td>
                            <td>{userType_name}</td>
                            <td>{zoneno}</td>
                            <td>{gatname.toString()}</td>
                            <td>
                              <Icon.PencilSquare size={22} className='ms-2 me-4 icon'
                                onClick={()=>{ setShowEdit(true); setEditData(itm)}}/> 
                              <Icon.Trash size={22} className='icon' 
                                onClick={()=>{ setShowDel(true); setUserId(user_id) }}/> 
                            </td>
                          </tr>
                        )
                      })
                  : <p className='noDataFound'>No Data Found</p>
                }   
                  </table>
              </div>
              
{paginate && 
      <Row>
        <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>
                <Icon.People className='me-2'/>Total - <strong>{data?.count}</strong>
            </h6>
        </Col>
        <Col md={8} className='d-flex justify-content-end'>
          <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange} 
          onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage}/>
        </Col>
    </Row>
}

            </div>
        </Container>

 {/* ---------------- ADD User Modal ----------------------------------------------------------- */}
  <Modal size='md' show={showAdd} backdrop='static' keyboard={false} centered onHide={closeModal}>
                      <Modal.Header closeButton> 
                          <Modal.Title> <h4 className='mb-0'>
                            <Icon.PlusCircle size={20} className='me-2'/>Add User</h4> 
                          </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      <Row>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                              <Form.Label>Name</Form.Label>
                              <Form.Control  placeholder="Enter name" value={name} onChange={(e)=>{ 
                                if (!e.target.value.match(/[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/) &&
                                  e.target.value.trim()
                              ) {
                                setName(e.target.value);
                              } else if (e.target.value.length === 0) {
                                setName(e.target.value);
                              }
                              e.target.value.length>0 ? setNameErr(false): setNameErr(true); }}/>
                              {nameErr ? <p className='errMsg'>Please enter user name</p> : ''}
                        </Form.Group>
                      </Col>
                      
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control  placeholder="Enter mobile number" value={mobile} onChange={(e) => { 
                                if (e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10) {
                                    setMobile(e.target.value);
                                } else if (e.target.value.length === 0) {
                                  setMobile(e.target.value);
                                }
                                e.target.value.length > 0 ? setMobileErr(false) : setMobileErr(true);
                              }} />
                            {mobileErr ? <p className='errMsg'>Please enter valid mobile no.</p> : ''}
                        </Form.Group>
                      </Col>
                       
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Email ID</Form.Label>
                            <Form.Control  placeholder="Enter email" value={email} onChange={(e)=>{ 
                            setEmail(e.target.value);}}
                            
                            //   if (!e.target.value.match(/[+#$&%!~=^_:()/\/{}\[\]|/*/\//\-/?<>,;`'""/]/) &&
                            //     e.target.value.trim()
                            // ) {
                            //   setEmail(e.target.value);
                            // } else if (e.target.value.length === 0) {
                            //   setEmail(e.target.value);
                            // }
                            // e.target.value.length>0 ? setEmailErr(false): setEmailErr(true);
                            />
                             {/* {emailErr ? <p className='errMsg'>Please enter valid email id</p> : ''} */}
                        </Form.Group>
                      </Col>
                      
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Password</Form.Label>
                            <InputGroup>
                            <Form.Control type={showPassword ? "text" : "password"}  placeholder="Enter password" value={pass} onChange={(e) => { setPass(e.target.value)
                                e.target.value.length > 0 ? setPassErr(false) : setPassErr(true);
                              }} />
                          
                            <InputGroup.Text>{ showPassword ? <Icon.EyeSlash size={20} style={{ cursor:'pointer' }} onClick={ () => setshowPassword(!showPassword) } /> 
                              : <Icon.Eye size={20} style={{ cursor:'pointer' }} onClick={ () => setshowPassword(!showPassword) } /> }
                              </InputGroup.Text>
                            </InputGroup>
                            {passErr ? <p className='errMsg'>Please enter valid password</p> : ''}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group>
                        <Form.Label>Role</Form.Label>
                        <Select isMulti={false} options={roleOptions} value={role}
                           onChange={(option) => { setRole(option);  setRoleErr(false);
                         }}/>
                      {roleErr ? <p className='errMsg'>Please enter any role</p> : ''}
                        </Form.Group>
                      </Col>
                      
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Zone</Form.Label>
                            <Select isMulti={false}
                              options={zoneOption}
                              value={zone}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="Select Zone"
                              onChange={(option) => { setZone(option); 
                                                      setGat(null); 
                                                      setZoneErr(false);
                              }}
                              isSearchable={false}/>
                            {zoneErr ? <p className='errMsg'>Please select zone</p> : ''}
                        </Form.Group>
                      </Col>
                      
                      <Col md={12}>
                      <Form.Group className='mb-3'>
                          <Form.Label>Gat</Form.Label>
                          <Select isMulti={true}
                            isDisabled={!zone ? true : false}
                            options={gatOption}
                            value={gat}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Gat"
                            onChange={(option) => { setGat(option);
                                                    setGatErr(false)
                             }}
                            isSearchable={true}/>
                          {gatErr ? <p className='errMsg'>Please select gat</p> : ''}
                      </Form.Group>
                      </Col>
                      </Row>
                      </Modal.Body>

                      <Modal.Footer>
                        { 
                          loader ? <Spinner variant='primary'/> :
                        <Button className='primaryBtn' onClick={addUser}> 
                            <Icon.PlusCircle className='me-1'/>Add</Button>
                        }
                      </Modal.Footer>
         </Modal>


 {/* ---------------- Edit User Modal ----------------------------------------------------------- */}
    <Modal size='md' show={showEdit} backdrop='static' keyboard={false} centered onHide={closeModal}>
                    <Modal.Header closeButton> 
                        <Modal.Title> <h4 className='mb-0'>
                          <Icon.PencilSquare size={20} className='me-2'/>Update User</h4> 
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        <Col md={6} className='mb-3'>
                        <Form.Group >
                          <Form.Label>Name</Form.Label>
                          <Form.Control  placeholder="Enter name" value={name} onChange={(e)=>{ 
                            if (!e.target.value.match(/[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/) &&
                              e.target.value.trim()
                          ) {
                            setName(e.target.value);
                          } else if (e.target.value.length === 0) {
                            setName(e.target.value);
                          }
                          e.target.value.length>0 ? setNameErr(false): setNameErr(true); }}/>
                          {nameErr ? <p className='errMsg'>Please enter user name</p> : ''}
                      </Form.Group>
                        </Col>


                  <Col md={6} className='mb-3'>
                    <Form.Group >
                            <Form.Label>Email ID</Form.Label>
                            <Form.Control  placeholder="Enter email" value={email} onChange={(e)=>{ 
                              setEmail(e.target.value);}}
                            //   if (!e.target.value.match(/[0-9+#$&%!~=^_:()/\/{}\[\]|/*/\//\-/?<>,;`'""/]/) &&
                            //     e.target.value.trim()
                            // ) {
                            //   setEmail(e.target.value);
                            // } else if (e.target.value.length === 0) {
                            //   setEmail(e.target.value);
                            // }
                            // e.target.value.length>0 ? setEmailErr(false): setEmailErr(true); 
                            />
                             {/* {emailErr ? <p className='errMsg'>Please enter email</p> : ''} */}
                        </Form.Group>
                    </Col>

                    <Col md={6} className='mb-3'>
                    <Form.Group>
                        <Form.Label>Role</Form.Label>
                        <Form.Control value={role?.value} disabled={true}/>
                    </Form.Group>
                    </Col>
                          
                    <Col md={6} className='mb-3'>
                    <Form.Group>
                          <Form.Label>Zone</Form.Label>
                          <Select isMulti={false}
                            options={zoneOption}
                            value={zone}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Zone"
                            onChange={(option) => { setZone(option); setGat(null); setZoneErr(false)}}
                            isSearchable={true}/>
                          {zoneErr ? <p className='errMsg'>Please select zone</p> : ''}
                      </Form.Group>
                    </Col>

                    <Col md={12} className='mb-3'>
                    <Form.Group>
                          <Form.Label>Gat</Form.Label>
                          <Select isMulti={true}
                            options={gatOption}
                            value={gat}
                            placeholder="Select Gat"
                            onChange={(option) => { setGat(option); setGatErr(false)
                           }}
                            isSearchable={true}/>
                          {gatErr ? <p className='errMsg'>Please select gat</p> : ''}
                      </Form.Group>
                    </Col>

                      </Row>
                </Modal.Body>

                    <Modal.Footer>
                      { 
                        loader ? <Spinner variant='primary'/> :
                      <Button className='primaryBtn' onClick={updateUser}> 
                          <Icon.PencilSquare className='me-1'/>Update</Button>
                      }
                    </Modal.Footer>
    </Modal>

 {/* ---------------- Delete User Modal ----------------------------------------------------------- */}
    <Modal size='md' show={showDel} backdrop='static' keyboard={false} centered onHide={closeModal}>
                <Modal.Header closeButton> 
                    <Modal.Title> <h4 className='mb-0'>
                      <Icon.Trash size={20} className='me-2'/>Delete User</h4> 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='text-center mb-0'>Are you sure to delete ?</h4>    
                </Modal.Body>

                <Modal.Footer>
                  { 
                    loader ? <Spinner variant='primary'/> :
                  <Button className='primaryBtn' onClick={deleteUser} disabled={loader}> 
                      <Icon.Trash className='me-2'/> Delete</Button>
                  }
                </Modal.Footer>
    </Modal>
    </div>
  )
}

export default ManageUsers;