import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Features/Common/ScrollToTop";
import Login from "./Features/Auth/Login";
import CommonLayout from "./Layout/CommonLayout";
import Profile from "./Features/Common/Profile";
import ManageUsers from "./Features/Admin/ManageUsers";
import Home from "./Features/Common/Home";
import PageNotFound from "./Features/Common/PageNotFound";
import ManageSurveys from "./Features/Admin/ManageSurveys";
import ViewSurvey from "./Features/Admin/ViewSurvey";
import PrivacyPolicy from "./Features/Common/PrivacyPolicy";
import ManageContractors from "./Features/Admin/ManageContractors";
import DaywiseReport from "./Features/Admin/DaywiseReport";
import VerificationReport from "./Features/Admin/VerificationReport";
import ManageFireOfficers from "./Features/Admin/ManageFireOfficers";
import NearbyReport from "./Features/Admin/NearbyReport";
import SurveyImages from "./Features/Admin/SurveyImages";
import ViewContractors from "./Features/Admin/ViewContractors";
import SurveyImgVerification from "./Features/Admin/SurveyImgVerification";
import Home2 from "./Features/Common/Home2";
import ManageMavims from "./Features/Admin/ManageMavims";
import Formone from "./Features/Citizen/Formone";
import Formtwo from "./Features/Citizen/Formtwo";
import Formthree from "./Features/Citizen/Formthree";
import CitizenLayout from "./Layout/CitizenLayout";
import SubmitSuccess from "./Features/Citizen/SubmitSuccess";
import SurveyForm from "./Features/Citizen/SurveyForm";

function App() {
  const userState = useSelector((state) => state?.surveylogin?.userData?.data);
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/" element={<SurveyForm />} />
          <Route path="/CitizenLayout" element={<CitizenLayout />}>
            <Route path="/CitizenLayout/surveyForm" element={<SurveyForm />} />
            <Route path="/CitizenLayout/formone" element={<Formone />} />
            <Route path="/CitizenLayout/Formtwo" element={<Formtwo />} />
            <Route path="/CitizenLayout/Formthree" element={<Formthree />} />
            <Route path="/CitizenLayout/success" element={<SubmitSuccess />} />
          </Route>

          {
            userState?.userType_keyname === "admin" &&
            <Route path='/dashboard' element={<CommonLayout />}>
              <Route path='/dashboard' element={<Home />} />
              <Route path='/dashboard/manage-users' element={<ManageUsers />} />
              <Route path='/dashboard/mahilawise-report' element={<ManageContractors />} />
              <Route path='/dashboard/fireofficer-report' element={<ManageFireOfficers />} />
              <Route path='/dashboard/mavim-report' element={<ManageMavims />} />
              <Route path='/dashboard/view-single-survey' element={<ViewContractors />} />
              <Route path='/dashboard/manage-surveys' element={<ManageSurveys />} />
              <Route path='/dashboard/view-survey' element={<ViewSurvey />} />
              <Route path='/dashboard/daywise-report' element={<DaywiseReport />} />
              <Route path='/dashboard/verification-report' element={<VerificationReport />} />
              <Route path='/dashboard/nearby-report' element={<NearbyReport />} />
              <Route path='/dashboard/survey-images' element={<SurveyImages />} />
              <Route path='/dashboard/survey-image-verification' element={<SurveyImgVerification />} />
              <Route path='/dashboard/profile' element={<Profile />} />
            </Route>
          }

          {
            userState?.userType_keyname === "mavim" &&
            <Route path='/dashboard' element={<CommonLayout />}>
              <Route path='/dashboard' element={<Home2 />} />
              <Route path='/dashboard/manage-users' element={<ManageUsers />} />
              <Route path='/dashboard/manage-surveys' element={<ManageSurveys />} />
              <Route path='/dashboard/view-survey' element={<ViewSurvey />} />
              <Route path='/dashboard/survey-images' element={<SurveyImages />} />
              <Route path='/dashboard/survey-image-verification' element={<SurveyImgVerification />} />
              <Route path='/dashboard/profile' element={<Profile />} />
            </Route>
          }


          {
            userState?.userType_keyname === "dataoperator" ?
              <Route path='/dashboard' element={<CommonLayout />}>
                <Route path='/dashboard' element={<Home2 />} />
                <Route path='/dashboard/survey-images' element={<SurveyImages />} />
                <Route path='/dashboard/view-survey' element={<ViewSurvey />} />
                <Route path='/dashboard/survey-image-verification' element={<SurveyImgVerification />} />
                <Route path='/dashboard/profile' element={<Profile />} />
              </Route>
              : ''
          }

          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
