import React, { useEffect, useState } from 'react'
import { Container, Row, Button, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

const ViewSurvey = () => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const [loader, setLoader] = useState(false);
    const [survey, setSurvey] = useState('');


    useEffect(()=>{
        getSurveyById();
    },[])
    
    const getSurveyById= async ()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/surveys/getSurveyBySurveyId2', {
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({ surveyId : location?.state })
        }).then((res)=>res.json()).then((response)=>{
            console.log("response", response);
            setSurvey(response?.data);
            setLoader(false);
        }).catch((err)=>{
            console.error('Error while view survey', err);
            setLoader(false);        
        })
    }

    const openImageInNewTab = (imageUrl) => {
        window.open(imageUrl, '_blank');
      };
      
  return (
    <div className='ViewSurvey outletPadding mb-5'>
        <Container>
            <Button className='primaryBtn' onClick={()=>navigate(-1)}>
                <Icon.ArrowLeft className='me-2'/>Back
            </Button>
            <Row>
                <h4 className='mb-0 text-center'>
                <Icon.Clipboard2Data className='me-1'/> Survey Details</h4>
            </Row>

            {
                loader ? <center><Spinner variant='primary mt-4'/></center> :
                <Row className="outer-wrapper mx-auto mt-4">
                <div className="table-wrapper">
                        <table data-aos="fade-up" data-aos-delay="200">
                            <tbody>
                                <tr>
                                    <th><Icon.Code className='me-2'/>Property Code</th>
                                    <td>{survey?.propertyCode ? survey?.propertyCode :'-' }</td>
                                </tr>

                                <tr>
                                    <th> <Icon.Bullseye className='me-2'/>Zone</th>
                                    <td>{survey?.zonename+' - '+survey?.zoneno}</td>
                                </tr>

                                <tr>
                                    <th><Icon.Bullseye className='me-2'/>Gat</th>
                                    <td>{survey?.gatname}</td>
                                </tr>
                                <tr>
                                <th><Icon.Building className='me-2'/>Building Floors</th>
                                    <td>{survey?.buildingFloors}</td>
                                </tr>
                                <tr>
                                <th><Icon.Clock className='me-2'/>Business Start Date</th>
                                    <td>{survey?.businessStartDate}</td>
                                </tr>
                                <tr>
                                    <th><Icon.Building className='me-2'/>Property Name</th>
                                    <td>{survey?.propertyName}</td>
                                </tr>
                                <tr>
                                    <th><Icon.GeoAlt className='me-2'/>Property Address</th>
                                    <td>{survey?.propertyAddress}</td>
                                </tr>
                                <tr>
                                    <th><Icon.GeoAlt className='me-2'/>Business Address</th>
                                    <td >{survey?.businessAddress}</td>
                                </tr>
                                <tr>
                                    <th><Icon.GeoAlt className='me-2'/>Final Business Address</th>
                                    <td >{survey?.finalBusinessAddress}</td>
                                </tr>
                                <tr>
                                    <th > <Icon.Person className='me-2'/>Owner Name</th>
                                    <td>{survey?.ownerName}</td>
                                </tr>
                                <tr>
                                <th><Icon.Telephone className='me-2'/>Owner Mobile</th>
                                    <td>{survey?.ownerMobile}</td>
                                </tr>
                                <tr>
                                    <th><Icon.Envelope className='me-2'/>Owner Email</th>
                                    <td>{survey?.ownerEmail ? survey?.ownerEmail  :'-' }</td>
                                </tr>
                                <tr>
                                    <th><Icon.ArrowRight className='me-2'/>Property Area Foot</th>
                                    <td>{survey?.propertyAreaFoot ? survey?.propertyAreaFoot : '-'}</td>
                                </tr>
                                <tr>
                                <th><Icon.ArrowRight className='me-2'/>Property Area Meter</th>
                                    <td>{survey?.propertyAreaMeter ? survey?.propertyAreaMeter :'-'}</td>
                                </tr>
                                <tr>
                                <th><Icon.Building className='me-2'/>Property Floor</th>
                                    <td>{survey?.propertyFloor ? survey?.propertyFloor :'-'}</td>
                                </tr>
                                <tr>
                                    <th><Icon.Fire className='me-2'/>Fire Extinguisher Equipments</th>
                                    <td>{survey?.fireExtinguisherEquipments ? survey?.fireExtinguisherEquipments?.map((itm)=>itm).join(', ') :'-'}</td>
                                </tr>
                                <tr>
                                    <th><Icon.Person className='me-2'/>Survey Person Name</th>
                                    <td>{survey?.surveyByName}</td>
                                </tr>
                                <tr>
                                <th><Icon.Telephone className='me-2'/>Survey Person Mobile</th>
                                    <td>{survey?.surveyByMobile}</td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Outside Image</th>
                                    <td>{
                                    survey?.outsideImage?.length > 0 ? 
                                         survey?.outsideImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="Outside"
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>
                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Inside Image</th>
                                    <td>{
                                        survey?.insideImage?.length > 0 ? 
                                         survey?.insideImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="Inside"
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>
                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Satha Parvana Image</th>
                                    <td>{
                                        survey?.sathaParvanaImage?.length > 0 ? 
                                         survey?.sathaParvanaImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="Satha Parvana"
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Satha ShopAct License Image</th>
                                    <td>{
                                        survey?.sathaShopActLicenseImage?.length > 0 ? 
                                         survey?.sathaShopActLicenseImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Business Parvana Image</th>
                                    <td colSpan={9}>{
                                        survey?.businessParvanaImage?.length > 0 ? 
                                         survey?.businessParvanaImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        <div>
                                         <Icon.CardImage className='me-2'/> Business ShopAct License Image
                                        </div>
                                    </th>
                                    <td colSpan={9}>{
                                        survey?.businessShopActLicenseImage?.length > 0 ? 
                                         survey?.businessShopActLicenseImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Food License Image</th>
                                    <td colSpan={9}>{
                                        survey?.isFoodLicenseImage?.length > 0 ? 
                                         survey?.isFoodLicenseImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire NOC Image</th>
                                    <td colSpan={9}>{
                                        survey?.fireNOCImage?.length > 0 ? 
                                         survey?.fireNOCImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="" 
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire Annual Image</th>
                                    <td>{
                                        survey?.fireAnnualImage?.length > 0 ? 
                                         survey?.fireAnnualImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire Safety Certificate Image</th>
                                    <td colSpan={9}>{
                                        survey?.fireSafetyCertificateImage?.length > 0 ? 
                                         survey?.fireSafetyCertificateImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Electric Meter Image</th>
                                    <td colSpan={9}>{
                                        survey?.electricMeterImage?.length > 0 ? 
                                         survey?.electricMeterImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire Ext. Equipment Image</th>
                                    <td colSpan={9}>{
                                        survey?.fireExtinguisherEquipmentImage?.length > 0 ? 
                                         survey?.fireExtinguisherEquipmentImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Hazardous Equipment Image</th>
                                    <td colSpan={9}>{
                                        survey?.hazardousEquipmentImage?.length > 0 ? 
                                         survey?.hazardousEquipmentImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Attic Image</th>
                                    <td colSpan={9}>{
                                        survey?.atticImage?.length > 0 ? 
                                         survey?.atticImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Basement Image</th>
                                    <td>{
                                        survey?.basementImage?.length > 0 ? 
                                         survey?.basementImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Residential Image</th>
                                    <td>{
                                        survey?.residentialImage?.length > 0 ? 
                                         survey?.residentialImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                               
                            </tbody>
                        </table>
              </div>
              </Row>

            }

            {/* {
                loader ? <center><Spinner variant='primary mt-4'/></center>:
                <Row className='justify-content-center mt-4' data-aos="fade-up" data-aos-delay='100'>
                <Col md={10}>
                    <Card>
                        <Row className='d-flex flex-column flex-md-row'>
                            <div className='keys'>
                                <div className='value'>
                                    <Icon.Code className='me-2'/><h6>Property Code - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Building className='me-2'/><h6>Property Name - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.GeoAlt className='me-2'/><h6>Property Address - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Bullseye className='me-2'/><h6>Zone - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Bullseye className='me-2'/><h6>Gat - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Building className='me-2'/><h6>Building Floors - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.GeoAlt className='me-2'/><h6>Business Address - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Clock className='me-2'/><h6>Business Start Date - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.GeoAlt className='me-2'/><h6>Final Business Address - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Toggle2On className='me-2'/><h6>Rental Status - </h6>
                                </div> <div className='value'>
                                    <Icon.Person className='me-2'/><h6>Owner Name - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Telephone className='me-2'/><h6>Owner Mobile - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.ArrowRight className='me-2'/><h6>Property Area Foot - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.ArrowRight className='me-2'/><h6>Property Area Meter - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Building className='me-2'/><h6>Property Floor - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.GeoAlt className='me-2'/><h6>Property WorkPlace - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Fire className='me-2'/><h6>Fire Extinguisher Equipments - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Building className='me-2'/><h6>Sub Property Type - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Person className='me-2'/><h6>Survey Person Name  - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Telephone className='me-2'/><h6>Survey Person Mobile  - </h6>
                                </div>
                            </div>
                            <Col md={6}>
                                <p>{survey?.propertyCode}</p>
                                <p>{survey?.propertyName}</p>
                                <p>{survey?.propertyAddress}</p>
                                <p>{survey?.zonename+' - '+survey?.zoneno}</p>
                                <p>{survey?.gatname}</p>
                                <p>{survey?.buildingFloors}</p>
                                <p>{survey?.businessAddress}</p>
                                <p>{survey?.businessStartDate}</p>
                                <p>{survey?.finalBusinessAddress}</p>
                                <p>{survey?.ownerName}</p>
                                <p>{survey?.ownerMobile}</p>
                                <p>{survey?.propertyAreaFoot ? survey.propertyAreaFoot :'-'}</p>
                                <p>{survey?.propertyAreaMeter ? survey.propertyAreaMeter :'-'}</p>
                                <p>{survey?.propertyFloor}</p>
                                <p>{survey?.propertyWorkPlace}</p>
                                <p>{survey?.fireExtinguisherEquipments?.map((itm)=>itm)}</p>
                                <p>{survey?.subPropertyType}</p>
                                <p>{survey?.surveyByName}</p>
                                <p>{survey?.surveyByMobile}</p>
                            </Col>
                        </Row>      
                    </Card>
                </Col>
            </Row> 
            } */}
           
        </Container>
    </div>
  )
}

export default ViewSurvey;