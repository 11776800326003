import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner, Form, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '../Common/Pagination';
import * as XLSX from 'xlsx';
import Skeleton from '../Common/Skeleton';

const ManageSurveys = () => {

    const navigate = useNavigate();

    const [data, setData] = useState();
    const [loader, setLoader] = useState(false);

    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(50);
    const [noOfPages, setNoOfPages] = useState();

    // States for Mobile search filter ----------------
    const [searchMobile, setSearchMobile] = useState('');
    const [searchMobileErr, setSearchMobileErr] = useState(false);

    const [zoneList, setZoneList] = useState('');
    const [zonekey, setZonekey] = useState('');
    const [zoneErr, setZoneErr] = useState(false);
    const [download, setDownload] = useState(false);
    const [excelLoader, setExceLoader] = useState(false);

    const [filters, setFilter] = useState('');
    const [radio, setRadio] = useState('');
    const [filtersErr, setFiltersErr] = useState('');

    const [propertyTypeList, setPropertyTypeList] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [propertyTypeErr, setPropertyTypeErr] = useState('');

    const [subPropertyTypeList, setSubPropertyTypeList] = useState('');
    const [subPropertyType, setSubPropertyType] = useState('');


    // function for pagination
        const handlePageChange = (pageNumber) => {
            setPageNo(pageNumber);
        };

        const handleDocsPerPage = (docsPageProp) => {
            setPageNo(1)
            setDocPerPage(docsPageProp);
        }

    useEffect(() => {
        getAllSurveys();
    }, [pageNo, docPerPage])

    useEffect(()=>{
        getAllZoneList();
        getAllPropertyType();
    },[])

    useEffect(()=>{
        getAllSubPropertyTypeList();
    },[propertyType])

    const getAllZoneList = async()=>{
        await fetch(process.env.REACT_APP_BASE_URL  + '/zone/zoneList', {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        }).then((res)=>res.json()).then((response)=>{
            setZoneList(response?.data);
        }).catch((Err)=>{
            console.log("Err while getting zonelist", Err);
        })
    }

    const getAllPropertyType = async ()=>{
        await fetch(process.env.REACT_APP_BASE_URL + '/pt/getAllPropertyType', {
            method:'GET'
        }).then((res)=>res.json()).then((response)=>{
            setPropertyTypeList(response?.data);
        }).catch((Err)=>{
            console.log("Err while getting propertType", Err);
        })
    }


    const getAllSubPropertyTypeList = async ()=>{
        await fetch(process.env.REACT_APP_BASE_URL + '/spt/getSubPropertyType', {
            method:'POST',
            headers:{ 'Content-Type':'application/json' },
            body:JSON.stringify({ parentTypeId : propertyType})
        }).then((res)=>res.json()).then((response)=>{
            setSubPropertyTypeList(response?.data);
        }).catch((Err)=>{
            console.log("Err while getting sub property type", Err);
        })
    }


    const getAllSurveys = async () => {
        setLoader(true);
        let payload = {
            docsPerPage: docPerPage,
            pageNo: pageNo,
            mobile: searchMobile,
            [filters]: radio,
            zonekey:zonekey,
            propertyTypeId:propertyType,
            subPropertyTypeId:subPropertyType,
        }
        // if(filters){
        //     payload.filters=radio;
        // }
        await fetch(process.env.REACT_APP_BASE_URL + `/surveys/getAllSurvey`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        }).then((res) => res.json()).then((response) => {
            setData(response?.data);
            setNoOfPages(response?.data?.noOfPages);
            setLoader(false);
        }).catch((err) => {
            console.log("Err while getting surveys", err);
            setLoader(false);
        })
    }


    // --------------- Search Survey By Mobile ------------------------------
            const searchByValidate = () => {
                let isValid = true;
                if (!searchMobile || searchMobile.length < 10) {
                    isValid = false;
                    setSearchMobileErr(true);
                }
                return isValid;
            }

            const searchByMobile = async () => {
                if (searchByValidate()) {
                    getAllSurveys();
                }
            }

    // --------------- Search Survey By Zone ------------------------------
            const searchByZoneValidate=()=>{
                let isValid = true;
                if (!zonekey) {
                    isValid = false;
                    setZoneErr(true);
                }
                return isValid;
            }
            const searchByZone = async()=>{
                if(searchByZoneValidate()){
                    await getAllSurveys();
                    setDownload(true);
                }
                else {
                    setLoader(false);
                }
            }

    //------------ Filter By Status ---------------------------
        const filterByStatusValidate =()=>{
            let isValid = true;
                if (!filters) {
                    isValid = false;
                    setFiltersErr('Please select valid status');
                }
                else if(!radio){
                    isValid = false;
                    setFiltersErr("Please check Yes/No Filter")
                }
                return isValid;
        }

        const filterByStatus = async()=>{
            if(filterByStatusValidate()){
                await getAllSurveys();
            }
        }

    //---------- Search By Property Type ----------------------

    const searchByPropertyValidate=()=>{
        let isValid = true;
        if (!propertyType) {
            isValid = false;
            setPropertyTypeErr("Please select valid property type");
        }
        else if(!subPropertyType){
            isValid = false;
            setPropertyTypeErr('Please select valid sub property type');
        }
        return isValid;
    }
      const searchByPropertyType = async()=>{
        if(searchByPropertyValidate()){
            await getAllSurveys();
        }
      }


    // ------------ Download Excel after searching by zone ---------------------
        const downloadSurveyExcel = async()=>{
            setExceLoader(true); 

            const keys_to_keep = ['propertyCode', 'propertyName', 'propertyType', 'propertyAddress', 'finalBusinessAddress', 'zonename', 'zoneno',,
            'ownerMobile', 'ownerName', 'surveyId', 'surveyDate', 'lat', 'long', 'propertyFloor', 'buildingFloors', 'addressLandmark', 
             'addressFloor', 'addressRoadColony', 'propertyAreaMeter', 'propertyAreaFoot', 'propertyWorkPlace', 'subPropertyType', 'subPropertyTypeOther',
            'businessAddress', 'ownerEmail', 'isRented', 'rentedPersonName', 'rentedPersonMobile', 'businessStartDate'  ];

            await fetch(process.env.REACT_APP_BASE_URL + `/surveys/getAllSurvey`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ zonekey:zonekey })
            }).then((res) => res.json()).then((response) => {

                const ExcelData = response?.data?.surveys?.map(e => {
                    const obj = {};
                    keys_to_keep.forEach(k => obj[k] = e[k])
                    return obj;
                  });

                  const downloadExcel = (heading, fileName) => {
                    let dataSet = [];
                    dataSet = heading;
                    const worksheet = XLSX.utils.json_to_sheet(dataSet);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, fileName);
                  };
        
                  downloadExcel(ExcelData, `${response?.data?.surveys[0].zonename}-Survey Report.xlsx`);
                  setExceLoader(false);
            }).catch((err) => {
                console.log("Err while getting surveys", err);
                setExceLoader(false);
            })
          setLoader(false);
        }


    return (
        <div className='ManageSurveys outletPadding'>
            <Container>
            <Row className='justify-content-between'>
                    <Col md={3}>
                    <Card className='searchCard'>
                        <Form.Label className='searchLabel'>Search by survey mobile</Form.Label>
                            <Form.Control placeholder='Enter Mobile' value={searchMobile}
                                onChange={(e) => {
                                    if (e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10) {
                                        setSearchMobile(e.target.value)
                                    }
                                    else if (e.target.value.length === 0) {
                                        setSearchMobile(e.target.value)
                                    }
                                    e.target.value.length > 0 ? setSearchMobileErr(false) : setSearchMobileErr(true);
                                }}/>
                                 {searchMobileErr ? <p className='errMsg'>Please enter valid mobile no.</p> : ''}
                            <Button className='primaryBtn mx-auto mt-2' onClick={searchByMobile}>
                                <Icon.Search className='me-2' size={15} />Search
                            </Button>
                    </Card>
                    </Col>


                <Col md={3}>
                    <Card className='searchCard'>
                        <Form.Label className='searchLabel'>Search by zone</Form.Label>
                        <Form.Select  value={zonekey} onChange={(e)=>{ setZonekey(e.target.value); 
                            e.target.value.length>0 && setZoneErr(false) }}>
                           <option value="" selected disabled>Select zone</option>
                            {
                                zoneList!==null && zoneList!==undefined && zoneList.length>0 ? 
                                zoneList?.map((itm)=>{
                                    return( 
                                        <option value={itm?.zonekey}>{itm?.zonename + '-' + itm?.zoneno}</option>
                                    )
                                })
                                :<option value="">No Data Found</option>
                            }
                        </Form.Select>
                        { zoneErr ? <p className='errMsg'>Please select valid zone</p> : '' }
                        <Button className='primaryBtn mx-auto mt-2' onClick={searchByZone}>
                            <Icon.Search className='me-2'/>Search
                        </Button>

                { download &&
                    <div className='text-center mt-3' data-aos="zoom-in" data-aos-delay="100">
                    <p style={{fontSize:'14px', marginBottom:'6px'}}>
                        Click below to download surveys from <strong>{data?.surveys[0]?.zonename}</strong>
                    </p>
                    {
                        excelLoader ? <center><Spinner variant='primary'/></center> :
                        <Button className='primaryBtn mx-auto' onClick={downloadSurveyExcel}>
                            <Icon.Download className='me-2' size={15}/>Download Excel
                        </Button>
                    }
                </div>
                }
                      </Card>
                </Col>

                    <Col md={3} className='my-auto mt-3 mt-md-0'>
                    <Card className='searchCard'>
                    <Form.Label className='searchLabel'>Filter by status</Form.Label>
                            <Form.Select value={filters} onChange={(e)=>{ setFilter(e.target.value); 
                            e.target.value.length>0 && setFiltersErr(''); }} className='me-3'>
                                <option value="" selected disabled>Select any filter</option>
                                <option value="isPropertyCode">PropertyCode</option>
                                <option value="isRented">Rented</option>
                                <option value="isSatha">Satha</option>
                                <option value="isVyavsay">Vyavsay</option>
                                <option value="isSathaParvana">Satha Parvana</option>
                                <option value="isSathaShopActLicense">Satha ShopAct License</option>
                                <option value="isBusinessParvana">Business Parvana</option>
                                <option value="isBusinessShopActLicense">Business ShopAct License</option>
                                <option value="isFoodLicense">FoodLicense</option>
                                <option value="isFireNOC">FireNOC</option>
                                <option value="isFireAnnualRenewal">Fire Annual Renewal</option>
                                <option value="isFireSafetyCertificate">Fire Safety Certificate</option>
                                <option value="electricMeter">Electric Meter</option>
                                <option value="isFireAnisFireExtinguisherEquipmentnualRenewal">Fire Ext. Equipment</option>
                                <option value="isHazardous">Hazardous</option>
                                <option value="isAttic">Attic</option>
                                <option value="isBasement">Basement</option>
                                <option value="isResidentialPurpose">Residential Purpose</option>
                                <option value="isDetailsGiven">Details Given</option>
                            </Form.Select>
                            <span style={{ display:filters ? "flex" : "none" }} className='my-2'>
                                <Form.Check inline value="true" label="Yes" name="group1" type="radio"
                                    onChange={(e) => { setRadio(e.target.value); e.target.value.length>0 && setFiltersErr(''); }} />
                                <Form.Check inline value="false" label="No" name="group1" type="radio"
                                    onChange={(e) =>{ setRadio(e.target.value); e.target.value.length>0 && setFiltersErr(''); }} />
                            </span>
                            {filtersErr ? <p className='errMsg'>{filtersErr}</p> :''}
                            <Button className='primaryBtn mx-auto mt-2' onClick={filterByStatus}>
                                <Icon.Funnel className='me-2'/>Filter
                            </Button>
                        </Card>
                    </Col>

                    <Col md={3}>
                    <Card className='searchCard'>
                        <Form.Label className='searchLabel'>Search by Property Type</Form.Label>
                        <Form.Select  value={propertyType} onChange={(e)=>{ setPropertyType(e.target.value); 
                            e.target.value.length>0 && setPropertyTypeErr('') }}>
                           <option value="" selected disabled>Select Type</option>
                            {
                                propertyTypeList!==null && propertyTypeList!==undefined && propertyTypeList.length>0 ? 
                                propertyTypeList?.map((itm)=>{
                                    return( 
                                        <option value={itm?.propertyTypeId}>{itm?.propertyType}</option>
                                    )
                                })
                                :<option value="">No Data Found</option>
                            }
                        </Form.Select>

                        { propertyType && <Form.Select className='mt-2' value={subPropertyType} onChange={(e)=>{ setSubPropertyType(e.target.value); 
                            e.target.value.length>0 && setPropertyTypeErr(false) }}>
                                <option value="" selected disabled>Select Type</option>
                            {
                                subPropertyTypeList!==null && subPropertyTypeList!==undefined && subPropertyTypeList.length>0 ? 
                                subPropertyTypeList?.map((itm)=>{
                                    return( 
                                        <>
                                        <option value={itm?.subPropertyTypeId}>{itm?.subPropertyType}</option>
                                      </>
                                    )
                                })
                                : <option value="">No Data Found</option>
                            }
                        </Form.Select>
                        }
                            
                        { propertyTypeErr ? <p className='errMsg'>{propertyTypeErr}</p> : '' }
                        <Button className='primaryBtn mx-auto mt-2' onClick={searchByPropertyType}>
                            <Icon.Search className='me-2'/>Search
                        </Button>
                    </Card>
                    </Col>
                </Row>



                <div className="outer-wrapper mx-auto mt-4">
                    {
                       
                            <>
                                <div className="table-wrapper mt-2" style={{ maxHeight: download? '45vh' : '58vh' }}>
                                    <table data-aos="fade-up" data-aos-delay="200">
                                        <thead>
                                            <th>Sr. No.</th>
                                            <th>Property Code</th>
                                            <th>Property Name</th>
                                            <th>Owner Name</th>
                                            <th>Owner Mobile</th>
                                            <th>Property Type</th>
                                            <th>Property Address</th>
                                            <th>Business Address</th>
                                            <th>Action</th>
                                        </thead>
                                        {

                                             loader ? <Skeleton rows={10} cols={10}/> :
                                            data?.surveys !== null && data?.surveys !== undefined && data?.surveys?.length > 0 ?
                                                data?.surveys?.map((itm, index) => {

                                                    const { surveyId, propertyCode, propertyName, ownerName, ownerMobile, propertyType, finalBusinessAddress, propertyAddress, } = itm

                                                    return (
                                                        <tr key={Math.random() * 999999999}>
                                                            <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                                            <td>{propertyCode ? propertyCode : '-'}</td>
                                                            <td style={{ maxWidth: '15vw' }}>{propertyName ? propertyName : '-'}</td>
                                                            <td style={{ maxWidth: '15vw' }}>{ownerName}</td>
                                                            <td>{ownerMobile}</td>
                                                            <td style={{ maxWidth: '14vw' }}>{propertyType}</td>
                                                            <td style={{ maxWidth: '18vw' }}>{propertyAddress ? propertyAddress : '-'}</td>
                                                            <td style={{ maxWidth: '20vw' }}>{finalBusinessAddress}</td>
                                                            <td><Icon.Eye className='ms-2 icon' size={24}
                                                                onClick={() => navigate('/dashboard/view-survey', { state: surveyId })} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                : <p className='noDataFound'>No Data Found</p>
                                        }
                                    </table>
                                </div>

                                <Row>
                                    <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
                                        <h6 className='text-center'>
                                            <Icon.BarChart className='me-2' />Total - <strong>{data?.count}</strong>
                                        </h6>
                                    </Col>
                                    <Col md={8} className='d-flex justify-content-end'>
                                        <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
                                            onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
                                    </Col>
                                </Row>
                            </>
                    }
                </div>
            </Container>
        </div>
    )
}

export default ManageSurveys;