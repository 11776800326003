import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import { Button, Spinner, Accordion, OverlayTrigger, Popover} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../Auth/LoginSlice';
import { setActiveKey } from '../Common/ActiveKeySlice';
import Refresh from '../../Assets/Refresh.svg'; 

const Menubar = () => {

  const userState = useSelector(state => state.surveylogin.userData.data);
  const activeKeyRedux = useSelector(state => state.surveyActiveData.activeNumberKey);

  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute('data-visible');
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);
      document.querySelector("body").style.overflow = 'hidden';
    }
    else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = 'auto';
    }
  }


  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout(null));
    dispatch(setActiveKey(1));
    navigate('/');
    localStorage.removeItem('persist:root')
    setLoader(false);
  }

  const entering = (e) => {
    e.children[0].style.borderTopColor = 'white';
    e.children[1].style.backgroundColor = 'white';
    e.children[0].style.borderRadius = '12px';
    e.children[1].style.borderRadius = '12px';
  };

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(setActiveKey({ activeKey: activeKey, subActiveKey: subActiveKey, activeTab: tabName }));
  };

  const Popup = (
    <Popover>
      <Popover.Body>
        <div className='PopupData'>
          <div>
            <Icon.Person className='me-1' size={18} /><p><strong>Name - </strong>{userState?.name }</p>
          </div>

          <div>
            <Icon.Briefcase className='me-2' size={14} /><p><strong>Role - </strong>{userState?.userType_keyname.replace(/^./, userState?.userType_keyname[0].toUpperCase())}</p>
          </div>
          <div>
            <Icon.Telephone className='me-2' size={14} /><p><strong>Mobile - </strong>{userState?.mobile}</p>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  )

  // const RefreshOverlay =()=> (
  //   <Tooltip id="button-tooltip">
  //   Simple tooltip
  // </Tooltip>
  // )


  return (

    <nav>
      <Link className='d-flex align-items-center'>
        <h5 className='mb-0 ms-2'>{activeKeyRedux.activeTab}</h5>
      </Link>

      <div className='d-flex align-items-center'>
          <abbr title='Refresh' className='me-2 d-block d-lg-none'>
            <img src={Refresh} alt="refresh" className='me-2 refresh' style={{width:'30px'}} onClick={()=>{ window.location.reload(false)}}/>
          </abbr>
        <Icon.PersonFill className='me-2 d-block d-lg-none' size={35} style={{ cursor: 'pointer' }}
          onClick={() => { navigate(`/dashboard/profile`); dispatch(setActiveKey({ activeKey: activeKeyRedux.activeKey, activeTab: 'Profile' })); }} />

        <div className="mobile-nav-toggle" aria-controls='selectNav' aria-expanded="false" onClick={setnav}>
          <div className='menu-btn__burger'></div>
        </div>
        
      </div>


      <ul className="lg-nav">
        <OverlayTrigger key='bottom' placement='bottom' overlay={Popup} onEntering={entering}>
          <div className='d-flex' style={{ border: 'none', cursor: 'pointer' }} onClick={() => { navigate(`/dashboard/profile`); dispatch(setActiveKey({ activeKey: activeKeyRedux.activeKey, activeTab: 'Profile' })); }}>
            <Icon.PersonFill className='me-2' size={35} />
            <div>
              <p style={{ fontWeight: '600', fontSize: '15px', marginBottom: '-4px' }}>{userState?.name}</p>
              <p style={{ fontSize: '12px', marginBottom: '0px' }}>{userState?.userType_name}</p>
            </div>
          </div>
        </OverlayTrigger>

        {/* <OverlayTrigger key='bottom' placement='bottom' 
          overlay={ <Tooltip id='bottom' > Refresh </Tooltip> }> */}
          <abbr title='Refresh'>
            <img src={Refresh} alt="refresh" className='ms-3 refresh' style={{width:'26px'}} onClick={()=>{ window.location.reload(false)}}/>
          </abbr>
        {/* </OverlayTrigger> */}

      </ul>

      <ul id="selectNav" className="mobile-nav" data-visible="false">

        <div className="d-flex justify-content-between flex-column h-100" >

          {
            userState?.userType_keyname==="admin" &&
            <Accordion flush activeKey={activeKeyRedux?.activeKey}>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Dashboard"); navigate('/dashboard'); setnav();}} className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseDoorFill className='me-2' /> : <Icon.HouseDoor className='me-2' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Manage Users"); navigate("/dashboard/manage-users"); setnav(); }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.PersonFill className='me-2' /> : <Icon.Person className='me-2' />}Manage Users</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Mahilawise Report"); navigate("/dashboard/mahilawise-report"); setnav(); }} className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 3 ? <Icon.PersonCircle className='me-2' /> : <Icon.PersonCircle className='me-2' />}Mahilawise Report</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={7} onClick={() => { handleAccordionChange(7, 7, "Fire Officer Report"); navigate("/dashboard/fireofficer-report"); setnav(); }} className={activeKeyRedux?.activeKey === 7 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 7 ? <Icon.Fire className='me-2' /> : <Icon.Fire className='me-2' />}Fire Officer Report</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={4} onClick={() => { handleAccordionChange(4, 4, "Manage Surveys"); navigate("/dashboard/manage-surveys"); setnav();}} className={activeKeyRedux?.activeKey === 4 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 4 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2' />}Manage Surveys</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={5} onClick={() => { handleAccordionChange(5, 5, "Weekly Report"); navigate("/dashboard/daywise-report"); setnav(); }} className={activeKeyRedux?.activeKey === 5 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 5 ? <Icon.CalendarRangeFill className='me-2' /> : <Icon.CalendarRange className='me-2' />}Weekly Report</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={6} onClick={() => { handleAccordionChange(6, 6, "Verification Report"); navigate("/dashboard/verification-report"); setnav(); }} className={activeKeyRedux?.activeKey === 6 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 6 ? <Icon.ShieldFillCheck className='me-2' /> : <Icon.ShieldCheck className='me-2' />}Verification Report</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={8} onClick={() => { handleAccordionChange(8, 8, "Nearby Report"); navigate("/dashboard/nearby-report"); setnav(); }} className={activeKeyRedux?.activeKey === 8 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 8 ? <Icon.GeoAltFill className='me-2' /> : <Icon.GeoAlt className='me-2' />}Nearby Report</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={9} onClick={() => { handleAccordionChange(9, 9, "Survey Images"); navigate("/dashboard/survey-images"); setnav(); }} className={activeKeyRedux?.activeKey === 9 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 9 ? <Icon.ImageFill className='me-2' /> : <Icon.Image className='me-2' />}Survey Images</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={10} onClick={() => { handleAccordionChange(10, 10, "Survey Image Verification"); navigate("/dashboard/survey-image-verification"); setnav(); }} className={activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 10 ? <Icon.CartCheckFill className='me-2' /> : <Icon.CartX className='me-2' />}Image Verification</Accordion.Header>
              </Accordion.Item>

            </Accordion>
          }

        {
          userState?.userType_keyname==="mavim" &&
            <Accordion flush activeKey={activeKeyRedux?.activeKey}>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Dashboard"); navigate('/dashboard'); setnav(); }} className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseDoorFill className='me-2' /> : <Icon.HouseDoor className='me-2' />}Home</Accordion.Header>
              </Accordion.Item>
              
              <Accordion.Item eventKey={4} onClick={() => { handleAccordionChange(4, 4, "Manage Surveys"); navigate("/dashboard/manage-surveys"); setnav();}} className={activeKeyRedux?.activeKey === 4 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 4 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2' />}Manage Surveys</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={9} onClick={() => { handleAccordionChange(9, 9, "Survey Images"); navigate("/dashboard/survey-images"); setnav(); }} className={activeKeyRedux?.activeKey === 9 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 9 ? <Icon.ImageFill className='me-2' /> : <Icon.Image className='me-2' />}Survey Images</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={10} onClick={() => { handleAccordionChange(10, 10, "Survey Image Verification"); navigate("/dashboard/survey-image-verification"); setnav(); }} className={activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 10 ? <Icon.CartCheckFill className='me-2' /> : <Icon.CartX className='me-2' />}Image Verification</Accordion.Header>
              </Accordion.Item>

            </Accordion>
          }


        {
           userState?.userType_keyname ==="dataoperator" &&
            <Accordion flush activeKey={activeKeyRedux?.activeKey}>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Dashboard"); navigate('/dashboard'); setnav(); }} className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseDoorFill className='me-2' /> : <Icon.HouseDoor className='me-2' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={9} onClick={() => { handleAccordionChange(9, 9, "Survey Images"); navigate("/dashboard/survey-images"); setnav(); }} className={activeKeyRedux?.activeKey === 9 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 9 ? <Icon.ImageFill className='me-2' /> : <Icon.Image className='me-2' />}Survey Images</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={10} onClick={() => { handleAccordionChange(10, 10, "Survey Image Verification"); navigate("/dashboard/survey-image-verification"); setnav(); }} className={activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""}>
                <Accordion.Header>{activeKeyRedux?.activeKey === 10 ? <Icon.CartCheckFill className='me-2' size={17}/> : <Icon.CartX className='me-2' size={17} />}Image Verification</Accordion.Header>
              </Accordion.Item>
            </Accordion>
          }


          <div className='px-2'>
            <Button className='LogoutBtn w-100' onClick={handleLogout}>{loader ? <Spinner animation="border" variant="light" className='spinner' /> : <><Icon.BoxArrowLeft className='me-2' />Logout</>}</Button>
          </div>

        </div>
      </ul>

    </nav>
  )
}

export default Menubar;